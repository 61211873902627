const TextArea = ({
  formik,
  label = "",
  name = "",
  id = "",
  // onChange = () => {},
  placeholder = "",
  className = "",
  // value = "",
  rows = 8,
  disabled = false,
  validation = true,
  isNotRemoveExtraSpace = false,
}) => {
  return (
    <div className="mb-2 w-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div className={label ? "mt-3" : "mt-2"}>
        <textarea
          id={id}
          name={name}
          placeholder={placeholder}
          value={formik.values[name] ?? ""}
          onChange={formik.handleChange}
          cols={10}
          rows={rows}
          onBlur={(e) => {
            const values = e.target.value;
            const removeSpace = values.replace(/\s+/g, " ").trim();
            formik.handleChange(e);
            if (!isNotRemoveExtraSpace) {
              formik.setFieldValue(name, removeSpace);
            }
            formik.handleBlur(e);
          }}
          disabled={disabled}
          className={`
          ${className}
          block w-full rounded-md border-0 shadow-sm ring-1 ring-offset ring-gray-300 
          placeholder:text-gray-400 focus:ring-2 focus:ring-offset focus:ring-[#475361] 
          text-sm
          ${
            disabled ? "cursor-not-allowed bg-gray-100 text-gray-900" : ""
          } resize-none 
        `}
        />
        {validation && formik?.touched?.[name] && formik?.errors?.[name] && (
          <div className="text-red-500 text-sm mt-1">
            {formik?.errors?.[name]}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextArea;
