import { CheckIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";
import Select, { components } from "react-select";

export default function GroupBySelect({
  initialvalue = null,
  options,
  placeholder,
  label,
  isSearchable,
  labelClassname,
  className,
  name,
  isDisabled = false,
  isClearable = false,
  handleChange = () => {},
  handleBlur = () => {},
}) {
  const controlStyles = {
    base: `border rounded-lg bg-white ${
      isDisabled ? "opacity-75 cursor-not-allowed" : "hover:cursor-pointer"
    }`,
    focus: "border-gray-400 ring-1 ring-gray-500",
    nonFocus: "border-gray-300 hover:border-gray-400",
  };

  const placeholderStyles = "text-gray-500 pl-1 py-0.5";
  const selectInputStyles = "pl-1 py-0.5";
  const valueContainerStyles = ` p-1 gap-1 ${
    isDisabled ? " !cursor-not-allowed" : ""
  }`;
  const singleValueStyles = `leading-7 ml-1 ${
    isDisabled ? " !cursor-not-allowed" : ""
  }`;
  const multiValueStyles =
    "bg-gray-100 rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
  const multiValueLabelStyles = "leading-6 py-0.5";
  const multiValueRemoveStyles =
    "border border-gray-200 bg-white hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
  const indicatorsContainerStyles = "p-1 gap-1";
  const clearIndicatorStyles =
    "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
  const indicatorSeparatorStyles = "bg-gray-300";
  const dropdownIndicatorStyles =
    "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
  const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
  const groupHeadingStyles =
    "pl-3 pt-2 pb-1 text-gray-900 text-sm bg-orange-300 rounded-sm font-bold";
  const optionStyles = {
    base: `px-3 py-2 rounded ${
      isDisabled ? "opacity-75 !cursor-not-allowed" : "hover:cursor-pointer"
    }`,
    focus: "bg-orange-100 active:bg-orange-400",
    selected: "after:text-orange-400 text-gray-500 ",
  };
  const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

  const { Option } = components;
  const IconOption = (props) => {
    return (
      <Option {...props} className="overflow-hidden !flex text-justify">
        <div className="flex items-center gap-2 w-[80%]">
          {props?.data?.label}
          {props?.isSelected && (
            <CheckIcon className="w-4 h-4 text-black shrink-0" />
          )}
        </div>
        <div className="bg-gray-400 min-h-[30px] w-0.5 mx-2"></div>
        <div className="w-[25%] flex-col text-xs items-center">
          <div className="w-full ">
            <label className="font-medium mr-2 block sm:inline-block">Sent In:</label>{" "}
            <label>{props?.data?.sentTime}</label>{" "}
            <label>{props?.data?.sentType}</label>
          </div>
          {props?.data?.isSameFrequency ? (
            <div className="w-full">
              <label className="font-medium mr-2 block sm:inline-block">Frequency Is:</label>{" "}
              <label>{props?.data?.freqTime}</label>{" "}
              <label>{props?.data?.freqType}</label>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Option>
    );
  };

  return (
    <div className={`${isDisabled ? "cursor-not-allowed" : ""}`}>
      <label
        className={`block text-sm font-medium leading-6 text-gray-900 ${labelClassname}`}
      >
        {label}
      </label>
      <Select
        name={name}
        value={initialvalue}
        onChange={(e) => handleChange(e)}
        onClick={(e) => e.stopPropagation()}
        unstyled
        placeholder={placeholder}
        className={`w-full mt-2 text-sm ${className} `}
        options={options}
        onBlur={(e) => handleBlur(e)}
        isSearchable={isSearchable}
        isClearable={isClearable}
        components={{ Option: IconOption }}
        classNames={{
          control: ({ isFocused }) =>
            clsx(
              isFocused ? controlStyles.focus : controlStyles.nonFocus,
              controlStyles.base
            ),
          placeholder: () => placeholderStyles,
          input: () => selectInputStyles,
          valueContainer: () => valueContainerStyles,
          singleValue: () => singleValueStyles,
          multiValue: () => multiValueStyles,
          multiValueLabel: () => multiValueLabelStyles,
          multiValueRemove: () => multiValueRemoveStyles,
          indicatorsContainer: () => indicatorsContainerStyles,
          clearIndicator: () => clearIndicatorStyles,
          indicatorSeparator: () => indicatorSeparatorStyles,
          dropdownIndicator: () => dropdownIndicatorStyles,
          menu: () => menuStyles,
          groupHeading: () => groupHeadingStyles,

          option: ({ isFocused, isSelected }) =>
            clsx(
              isFocused && optionStyles.focus,
              isSelected && optionStyles.selected,
              optionStyles.base
            ),
          noOptionsMessage: () => noOptionsMessageStyles,
        }}
        isDisabled={isDisabled}
      />
    </div>
  );
}
