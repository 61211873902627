import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the user slice
const initialState = {
  id: "", // User's ID
  permission: [], // User's Permission
  currentUser: {},
  notificationCount: 0,
  refetchCurrentUser: false,
  setUserFilter: {},
  columnOrder: [],
  propertyNotificationCount: 0,
};

// Create the user slice using user Permission
export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    user: (state, { payload }) => {
      state.id = payload?.id;
      state.permission = payload?.permission;
      state.columnOrder = payload?.columnOrder;
    },
    currentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    notificationCount: (state, { payload }) => {
      state.notificationCount = payload;
    },
    refetchCurrentUser: (state, { payload }) => {
      state.refetchCurrentUser = payload;
    },
    setUserFilter: (state, { payload }) => {
      state.userFilter = payload;
    },
    columnOrder: (state, { payload }) => {
      state.columnOrder = payload;
    },
    propertyNotificationCount: (state, { payload }) => {
      state.propertyNotificationCount = payload;
    },
  },
});

// Export the action creators
export const {
  user,
  currentUser,
  notificationCount,
  refetchCurrentUser,
  setUserFilter,
  columnOrder,
  propertyNotificationCount,
} = userSlice.actions;

// Export the user slice reducer
export default userSlice.reducer;
