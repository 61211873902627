import {
  ChatBubbleBottomCenterIcon,
  EnvelopeIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/outline";
import CustomTable from "Components/Common/CustomTable";
import ActionButton from "Components/Common/Fields/ActionButton";
import Button from "Components/Common/Fields/Button";
import CustomSelectField from "Components/Common/Fields/CustomSelectField";
import SearchInputField from "Components/Common/Fields/SearchInputField";
import ShowPageTitle from "Components/Common/ShowPageTitle";
import DateRangePickerModal from "Components/Leads/Modal/DateRangePickerModal";
import LeadDetailsModal from "Components/Tasks/LeadDetailsModal";
import {
  dateConvertToString,
  getLocalStorage,
  getOptionData,
  isValidArray,
  isValidObject,
  showDateAndTimeFormat,
  showSimpleDateFormat,
} from "Modules/util";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePendingMessageListQuery } from "Redux/pendingMessages/pendingMessagesQuery";
import { setBreadcrumb } from "Redux/themeSlice";
import ActionModal from "./ActionModal";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { TooltipWrapper } from "Components/Common/ToolTipWrapper";

export default function PendingMessages() {
  const [search, setSearch] = useState(""); //state for search
  const [rows, setRows] = useState([]); //state for table rows
  const [isLeadDetailsOpen, setIsLeadDetailsOpen] = useState(false); //state for lead detail modal
  const [leadData, setLeadData] = useState({}); // state for lead id
  const [selectedDrip, setSelectedDrip] = useState("all"); // state for selected drip for filter
  const [filterData, setFilterData] = useState(); // state for selected drip for filter
  const dripList = useSelector((s) => s?.option.dripList);
  const [activeDripList, setActiveDripList] = useState(); // state for Active Drips
  const dispatch = useDispatch(); // dispatch for breadcrumb
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const isActiveIndex = searchParams.get("type") === "email" ? 1 : 0;
  const [isOpenNestModal, setIsOpenNestModal] = useState({
    leadDetailsModal: false,
    addTaskModal: false,
    addCallLogModal: false,
    addDripModal: false,
    sendMailModal: false,
    makeCallModal: false,
    birthdayModal: false,
    deleteCallModal: false,
    deleteTaskModal: false,
    deleteDripModal: false,
    deleteNoteModal: false,
    deleteHistoryModal: false,
    deletePhoneModal: false,
    changePhoneStatusModal: false,
    changeEmailStatusModal: false,
    deleteEmailModal: false,
    AssignAISuggestedDripModal: false,
    AISuggestedDripApproveModal: false,
    reAssignDripModal: false,
    removeDripModal: false,
    dripHoverEditModal: false,
    mergeLeadModal: false,
  });

  // State for filter
  const [filter, setFilter] = useState({
    limit: parseInt(getLocalStorage("pendingMessagePage")) || 20,
    page: 1,
    search: "",
  });

  // Pending MessageList Query
  const {
    data: pendingMessagesList,
    isLoading: isPendingMessagesListLoading,
    isSuccess: isPendingMessagesListSuccess,
    refetch,
  } = usePendingMessageListQuery(filter);

  // getting pending message list & setting it in table
  useEffect(() => {
    if (!isPendingMessagesListLoading) {
      let list = pendingMessagesList?.data?.pendingMessageList?.response;
      if (isValidArray(list)) {
        setRows(list);
      } else {
        setRows([]);
      }
    }
  }, [pendingMessagesList]);

  // filter active lead from drip list
  useEffect(() => {
    let activeDrips = dripList?.filter((data) => data?.status === true);
    let dripOption = [
      { label: "All", value: "all" },
      ...getOptionData(activeDrips),
    ];
    setActiveDripList(dripOption);
  }, [dripList]);

  // updating filter
  useEffect(() => {
    if (selectedDrip || filterData) {
      if (selectedDrip !== "all") {
        setFilter({ ...filter, ...filterData, drip: selectedDrip, page: 1 });
      } else {
        let { drip, ...restFilter } = filter;
        setFilter({ ...restFilter, ...filterData });
      }
    }
  }, [selectedDrip, filterData]);

  // Set breadcrumb on component mount
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          title: "Pending Messages",
          path: "/pending-messages",
        },
      ])
    );
  }, []);

  function handleResetFilter() {
    setFilter({
      search: "",
      type: searchParams?.get("type") === "email" ? "mail" : "message",
    });
    setSearch("");
    setFilterData({});
    setSelectedDrip("all");
  }

  // Table Column
  const columns = [
    {
      field: "leadName",
      headerName: "Lead",
      width: 300,
      editable: false,
      resizable: false,
      sortable: false,
      cellRenderer: (cellInfo) => {
        let leadId = cellInfo?.data?.leadId;
        let leadName = cellInfo?.data?.leadName;
        return (
          <div
            onClick={() => {
              setLeadData({ leadId: leadId });
              setIsLeadDetailsOpen(true);
            }}
            title={leadName || ""}
            className="text-blue-500 font-semibold hover:cursor-pointer hover:underline"
          >
            {leadName}
          </div>
        );
      },
    },
    {
      field: "dripName",
      headerName: "Drip",
      editable: false,
      resizable: false,
      sortable: false,
      cellRenderer: (cellInfo) => {
        return (
          <span title={cellInfo?.data?.dripName || ""}>
            {cellInfo?.data?.dripName || ""}
          </span>
        );
      },
    },
    {
      field: "scheduleTime",
      headerName: "Schedule Time",
      editable: false,
      resizable: false,
      cellRenderer: (cellInfo) => {
        let scheduleTime = cellInfo?.data?.scheduleTime;
        return (
          <span title={showDateAndTimeFormat(scheduleTime)}>
            {showDateAndTimeFormat(scheduleTime) ?? ""}
          </span>
        );
      },
    },
    {
      field: "subject",
      headerName: "Subject",
      sortable: false,
      minWidth: 200,
      resizable: false,
      flex: 1,
      hide: isActiveIndex === 1 ? false : true,
      cellRenderer: (cellInfo) => {
        return (
          <div
            className=" leading-6 max-h-[150px] overflow-y-auto p-1"
            title={cellInfo?.data?.subject || ""}
            style={{ whiteSpace: "pre-wrap" }}
          >
            {cellInfo?.data?.subject || "-"}
          </div>
        );
      },
    },
    {
      field: "message",
      headerName: "Preview",
      sortable: false,
      minWidth: 700,
      resizable: false,
      flex: 1,
      cellRenderer: (cellInfo) => {
        return <TooltipWrapper message={cellInfo?.data?.message} />;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      resizable: false,
      cellRenderer: (cellInfo) => (
        <div className="flex space-x-3 mt-2">
          <ActionButton
            Icon={PencilSquareIcon}
            title={"Preview Action"}
            className="icon-btn-orange"
            onClick={() => {
              setIsActionModalOpen(true);
              setLeadData(cellInfo?.data);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (searchParams?.get("type") === "email") {
      setSearchParams({
        type: "email",
      });
    } else {
      setSearchParams({
        type: "message",
      });
    }
    setFilterData({
      ...filterData,
      type: searchParams?.get("type") === "email" ? "mail" : "message",
    });
  }, [searchParams]);

  return (
    <>
      <ShowPageTitle title={"pendingMessages"} />
      <div className="flex justify-between md2:items-center items-start mb-2 md2:flex-nowrap flex-wrap">
        <div className="lg1:w-[300px] w-[250px]">
          <SearchInputField
            setSearchInput={(value) => setSearch(value)}
            searchInput={search}
            globalSearch={(value) =>
              setFilter({
                ...filter,
                search: value,
              })
            }
          />
        </div>
        <div className="flex xs4:items-center items-start xs4:flex-nowrap flex-wrap xs4:space-y-0 space-y-2">
          <div className="flex xxs:space-x-2 space-x-0 xs4:mt-0 mt-2 xxs:flex-row flex-col xxs:space-y-0 space-y-2">
          <div className="flex">
            <Button
              isDisable={
                !isValidObject(filterData) &&
                filter?.search === "" &&
                selectedDrip === "all"
                  ? true
                  : false
              }
              title={"Reset Filter"}
              action={() => handleResetFilter()}
              className="btn-orange-sm"
            />
          </div>
          <div className="flex xl:w-[250px] w-[195px]">
            <DateRangePickerModal
              handleSelectDate={(startDate, endDate) => {
                setFilterData({
                  leadRepliedStartDate: dateConvertToString(startDate) ?? null,
                  leadRepliedEndDate: dateConvertToString(endDate) ?? null,
                });
              }}
              buttonLable={
                filterData?.leadRepliedEndDate ||
                filterData?.leadRepliedStartDate ? (
                  <>
                    {showSimpleDateFormat(filterData?.leadRepliedStartDate)} to{" "}
                    {showSimpleDateFormat(filterData?.leadRepliedEndDate)}
                  </>
                ) : (
                  "Select Lead Replied Date"
                )
              }
              defaultStartDate={""}
              defaultEndDate={""}
            />
          </div>
          </div>
          <div className="">
            <CustomSelectField
              options={activeDripList}
              name={"drip"}
              setNewSelectedOption={setSelectedDrip}
              classNames="xl:w-[300px] w-[200px] text-sm "
              placeholder="Select Drip"
              onlyValue={true}
              initialvalue={selectedDrip}
            />
          </div>
        </div>
      </div>
      <div className="flex items-start">
        <Tab.Group
          selectedIndex={isActiveIndex}
          onChange={(index) => {
            if (index === 0) {
              setSearchParams({ type: "message" });
            } else if (index === 1) {
              setSearchParams({ type: "email" });
            }
          }}
        >
          <Tab.List className="flex w-full">
            <Tab
              className={({ selected }) =>
                classNames(
                  "px-5 py-1 text-gray-700 focus:outline-none focus:ring-0 rounded-t-lg group flex  leading-6",
                  selected
                    ? "border-b-0 border-l border-r border-t border-gray-400 font-semibold rounded-t-md text-sm"
                    : "bg-white border-b border-b-gray-400  font-normal text-sm rounded-t-md hover:bg-white"
                )
              }
            >
              <ChatBubbleBottomCenterIcon
                className="mt-[2px] w-5 mr-2"
                aria-hidden="true"
              />
              Messages
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "px-5 py-1 text-gray-700 focus:outline-none focus:ring-0 group rounded-t-lg flex text-md leading-6",
                  selected
                    ? "border-b-0 border-l border-r border-t border-gray-400 font-semibold rounded-t-md text-sm"
                    : "bg-white border-b border-b-gray-400 font-normal text-sm rounded-t-md hover:bg-white"
                )
              }
            >
              <EnvelopeIcon
                className="w-5 mr-2 mt-[2px]                                                                                                                                                                                                          "
                aria-hidden="true"
              />
              Emails
            </Tab>
            <div className="w-full border-b border-gray-400"></div>
          </Tab.List>
        </Tab.Group>
      </div>
      <div className="mt-4 flex flex-col bg-white">
        <CustomTable
          listName={"pendingMessage"}
          rows={rows}
          columns={columns}
          filter={filter}
          pagination={true}
          setFilter={setFilter}
          isLoading={isPendingMessagesListLoading}
          totalRecords={
            isPendingMessagesListSuccess
              ? pendingMessagesList?.data?.pendingMessageList?.totalCount
              : 0
          }
        />
      </div>
      <LeadDetailsModal
        isOpen={isLeadDetailsOpen}
        setIsOpen={setIsLeadDetailsOpen}
        isLeadId={leadData?.leadId}
        setIsOpenNestModal={setIsOpenNestModal}
        isOpenNestModal={isOpenNestModal}
      />
      {isActionModalOpen && (
        <ActionModal
          isOpen={isActionModalOpen}
          isSetOpen={setIsActionModalOpen}
          setLeadData={setLeadData}
          leadData={leadData}
        />
      )}
    </>
  );
}
