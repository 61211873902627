import React, { useEffect, useRef, useState } from "react";
import { useGetSMSByLeadIdQuery } from "Redux/Leads/SMS/sms";
import {
  convertCapitalize,
  getAuthToken,
  isValidArray,
  isValidObject,
  showErrorMsg,
  showLeadDateTimeFormat,
  showSuccessMsg,
} from "Modules/util";
import Skeleton from "Components/Common/Fields/Skeleton";
import { useFormik } from "formik";
import { useQuickReplyMutation } from "Redux/Leads/lead";
import User from "Assets/Images/User.png";
import AISuggestionIcon from "../../Assets/icons/AiSuggestionIcon.svg";

import {
  CheckCircleIcon,
  ClockIcon,
  PaperAirplaneIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGenerateOpenAISMSResponseMutation } from "Redux/Leads/openAI";
import TextArea from "Components/Common/Fields/TextArea";
import { useDispatch } from "react-redux";
import { setIsAfterLoginNavigate } from "Redux/themeSlice";

const QuickMessages = () => {
  const [smsData, setSMSData] = useState({}); // state to store sms data
  const [isOptedOut, setIsOptedOut] = useState(""); // state to store isOptedOut validation

  const smsRef = useRef(null);
  const [searchParams] = useSearchParams(); // hooks to get parameters from url
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const leadId = searchParams.get("leadId"); // extract leadId from url

  const [generateAISmsResponse, { isLoading: isAISmsLoading }] =
    useGenerateOpenAISMSResponseMutation();

  const {
    data: getSMSByLeadId,
    isLoading: isSMSByLeadIdLoading,
    isError: isSmsByLeadIDFailed,
  } = useGetSMSByLeadIdQuery(
    {
      _id: leadId,
    },
    { skip: leadId ? false : true }
  );

  const [sendQuickMessage] = useQuickReplyMutation();

  useEffect(() => {
    let smsData = getSMSByLeadId?.data?.getSMSByLeadId;

    if (!isSMSByLeadIdLoading) {
      if (isValidObject(smsData)) {
        setSMSData(smsData);
        setIsOptedOut(smsData?.isOptedOut);
      } else {
        setSMSData({});
      }
    }
  }, [getSMSByLeadId, leadId]);

  useEffect(() => {
    // Automatically scroll to the latest message when messages update
    const smsScrollTime = setTimeout(() => {
      if (smsRef?.current) {
        smsRef.current.scrollTop = smsRef.current.scrollHeight;
      }
    }, 100);

    return () => clearTimeout(smsScrollTime);
  }, [smsData]);

  // function to navigate to profile page
  const handleProfileNavigation = () => {
    if (!getAuthToken()) {
      dispatch(setIsAfterLoginNavigate("/leads/detail/" + leadId));
    }
    navigate("/leads/detail/" + leadId);
  };

  const handleAutoAIGenerate = async () => {
    try {
      const res = await generateAISmsResponse({ id: leadId }); // Call the generateAISmsResponse function
      const generateOpenAIResponse = res?.data?.data?.generateOpenAIResponse; // Extract response data
      // Check if response data exists
      if (generateOpenAIResponse?.type === "success") {
        showSuccessMsg(generateOpenAIResponse?.message); // Show success message and set message field value if response is successful
        formik.setFieldValue("message", generateOpenAIResponse?.response);
      } else if (generateOpenAIResponse?.type === "errors") {
        showErrorMsg(generateOpenAIResponse?.message); // Show error message and revert isAiReply if response indicates error
      }
    } catch (error) {
      showErrorMsg("Error occured while Generating Message");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      message: "",
    },
    onSubmit: async (values) => {
      if (values?.message && !isOptedOut) {
        const leadNumber = smsData?.smsResponse?.filter(
          (value) => value?.status === "received"
        );

        let payload = {
          leadId: leadId,
          phoneNumber: leadNumber[leadNumber?.length - 1]?.from ?? "",
          message: values?.message,
        };

        try {
          let response = await sendQuickMessage(payload);
          if (response?.data?.data?.quickReply?.type === "success") {
            formik.setFieldValue("message", "");
          } else if (response?.data?.data?.quickReply?.type === "error") {
            showErrorMsg(response?.data?.data?.quickReply?.message);
            formik.setFieldValue("message", "");
          } else {
            showErrorMsg("Error occurred while Sending Message.");
            formik.setFieldValue("message", "");
          }
        } catch (error) {
          showErrorMsg("Error occurred while Sending Message.");
          formik.setFieldValue("message", "");
        }
      }
    },
  });

  return (
    <div className="h-screen flex flex-col">
      {/* Header with lead name */}
      <div className="sticky top-0 z-[99] flex justify-between h-16 items-center gap-x-4 border-b border-gray-200 !bg-[#D3D3D3] px-4 py-4 shadow-sm sm:gap-x-3 sm:px-6 lg:px-6">
        <div className="text-white font-bold flex items-center">
          {isSmsByLeadIDFailed ? (
            <></>
          ) : (
            <>
              <img
                className="h-8 w-8 inline-block mr-2"
                src={User}
                alt="Your Company"
              />
              {isSMSByLeadIdLoading ? (
                <Skeleton counter={1} height={"10px"} />
              ) : (
                <span
                  onClick={() => handleProfileNavigation()}
                  className="hover:cursor-pointer text-[#007BFF] hover:underline"
                >
                  {smsData?.name}
                </span>
              )}
            </>
          )}
        </div>
      </div>

      {/* Displaying SMS section */}
      <div
        ref={smsRef}
        className="flex-grow overflow-y-auto py-2 md:px-10 px-2"
      >
        {isSMSByLeadIdLoading ? (
          <Skeleton counter={3} height={"80px"} className="my-5" />
        ) : isValidArray(smsData?.smsResponse) ? (
          smsData?.smsResponse?.map((sms, index) => (
            <div
              key={index}
              className={`w-full flex ${
                sms?.status === "received" ? "justify-start" : "justify-end"
              }`}
            >
              <div
                className={`m-2 p-2 ring-2 xs2:w-[60%] w-[80%] ring-gray-200 bg-gray-100 shadow-md rounded-lg text-xs space-y-2 ${
                  sms?.status === "received"
                    ? "mr-2 my-3 bg-gray-100"
                    : "ml-2 !bg-gray-200"
                }`}
              >
                <div className="flex justify-end xs2:flex-row flex-col xs2:space-y-0 space-y-3">
                  <div className="text-gray text-end">
                    {showLeadDateTimeFormat(sms?.createdAt)}
                  </div>
                </div>

                <div className="flex justify-between">
                  <div className="w-[100%] break-all space-x-2 !break-normal">
                    <label>{sms?.message}</label>
                  </div>
                </div>

                <span
                  className={`flex items-end justify-end text-xs !mt-0.5 ${
                    sms?.status === "failed" || sms?.status === "undelivered"
                      ? "text-red-500"
                      : "text-green-600"
                  }`}
                >
                  {sms?.status === "failed" || sms?.status === "undelivered" ? (
                    <XCircleIcon className="text-red-500 w-[18px] h-[18px]" />
                  ) : sms?.status === "queued" ? (
                    <ClockIcon className="text-green-600 w-[18px] h-[18px]" />
                  ) : (
                    <CheckCircleIcon className="text-green-600 w-[18px] h-[18px]" />
                  )}
                  {sms?.status && convertCapitalize(sms?.status)}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center text-gray-600 text-xs h-full">
            <span className="self-center">No SMS history available.</span>
          </div>
        )}
      </div>

      {/* Message box section */}
      {isSmsByLeadIDFailed ? (
        <></>
      ) : isSMSByLeadIdLoading ? (
        <Skeleton counter={1} height={"60px"} className="my-2" />
      ) : (
        <div className="sticky bottom-0 bg-white border-t shadow-2xl">
          <div
            className={`flex items-center justify-center md:px-10 px-4 mt-1`}
          >
            {isOptedOut && (
              <span className="text-red-500 text-xs font-semibold h-min">
                (Number is Opted-Out)
              </span>
            )}
          </div>

          <div className="flex items-center md:px-4 px-2">
            <span className="mr-3 inline-block">
              <img
                src={AISuggestionIcon}
                alt="AiSuggestionIcon"
                className={`h-7 w-7 disabled:cursor-not-allowed ${
                  isOptedOut || !smsData?.smsResponse || isAISmsLoading
                    ? " opacity-75 cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() => {
                  if (!isAISmsLoading && smsData?.smsResponse) {
                    if (smsData?.smsResponse?.length !== 0 && !isOptedOut) {
                      handleAutoAIGenerate();
                    }
                  }
                }}
              />
            </span>

            {/* Message Box */}
            <div className="relative w-full">
              {/* Textarea with Send Button */}
              <TextArea
                id="message"
                label=""
                name="message"
                formik={formik}
                className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 overflow-y-auto resize-none  quick-message-scroll !pr-9"
                rows={2}
                onChange={formik.handleChange}
                onBlur={() => {}}
                autoComplete="message"
                placeholder="Enter Message"
                validation={false}
                disabled={isOptedOut}
              />

              {/* Send Button */}
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <span
                  className={`flex items-center justify-center w-8 h-8 ${
                    isOptedOut || !formik?.dirty
                      ? " opacity-70 cursor-not-allowed"
                      : "cursor-pointer"
                  } transition-colors duration-300`}
                  onClick={() => formik?.handleSubmit()}
                >
                  <PaperAirplaneIcon className="h-5 w-5 text-gray-700" />
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuickMessages;
