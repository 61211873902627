import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a settingsQuery object using serverApi.injectEndpoints
const DashboardQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    emailReportList: builder.query({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.emailReport(payload),
      }),
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const { useEmailReportListQuery } = DashboardQuery;
