import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DecoupledEditor } from "ckeditor5";
import React, { useState } from "react";
import { config } from "./editorAssets";
import "ckeditor5/ckeditor5.css";
import "./editor.css";

const CkEditor = ({ formik, name, editorRef, isDisabled = false }) => {
  const [isFocus, setIsFocus] = useState(false);

  const handleKeydown = (event, editor) => {
    const isEnter = event.key === "Enter";
    const isShiftEnter = event.shiftKey && isEnter;

    if (isShiftEnter) {
      // Default 'Shift + Enter' (new paragraph) action: Change to a normal Enter behavior
      event.preventDefault();
      editor.execute("enter");
    } else if (isEnter) {
      // Default 'Enter' (new line) action: Change to Shift + Enter (line break)
      event.preventDefault();
      editor.execute("shiftEnter");
    }
  };
  return (
    <>
      <div
        className={`!rounded-md shadow-md border border-gray-300 ${
          isFocus ? "border-gray-500 ring-inset ring-[#475361] ring-1" : ""
        }`}
      >
        <CKEditor
          editor={DecoupledEditor}
          data={formik?.values?.[name] || "<p><br></p>"}
          config={config}
          onFocus={(event, editor) => {
            editor.editing.view.focus();
            setIsFocus(true);
          }}
          onBlur={(event, editor) => {
            formik.setFieldTouched(name, true);
            setIsFocus(false);
          }}
          onReady={(editor) => {
            editorRef.current = { editor };

            editor.editing.view.document.on("keydown", (event, data) =>
              handleKeydown(data.domEvent, editor)
            );
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            formik?.setFieldValue([name], data);
          }}
          disabled={isDisabled}
        />
      </div>
      {formik.touched?.[name] && formik.errors?.[name] && (
        <div className="text-red-500 text-sm mt-[2px]">
          {formik?.errors?.[name]}
        </div>
      )}
    </>
  );
};

export default CkEditor;
