import { showLeadDateTimeFormat } from "Modules/util";
import React from "react";
import he from "he";

const EmailToolTipContent = ({ emailInfo }) => {
  const decodeHTMl = (preview) => {
    const decodedData = he.decode(preview || "<p><br/></p>");
    return decodedData;
  };

  return (
    <div className="max-h-[500px] overflow-y-auto text-justify space-y-1.5 p-1 min-w-[500px]">
      <div className="flex text-white space-x-1">
        <label className="font-bold">From:</label>
        <label className="flex">{emailInfo?.from || "-"}</label>
      </div>

      <div className="flex text-white space-x-1">
        <label className="font-bold">To:</label>
        <label className="flex">{emailInfo?.to || "-"}</label>
      </div>

      <div className="flex justify-between">
        <span className="flex space-x-1">
          <label className="font-bold">Type:</label>
          <label className="flex capitalize">{emailInfo?.type || "-"}</label>
        </span>
        <span className="text-white">
          {" "}
          {showLeadDateTimeFormat(emailInfo?.date)}
        </span>
      </div>

      {emailInfo?.type !== "received" ? (
        <div className="flex mt-1 space-x-1">
          <label className="font-bold"> Email Opened:</label>
          <span>
            {emailInfo?.isOpened === true ? (
              <span className="flex items-center">
                Opened
                <span className="pl-1 font-normal text-xs mt-[2px]">
                  {showLeadDateTimeFormat(emailInfo?.openedAt)}
                  {emailInfo?.openedCount
                    ? `(${emailInfo?.openedCount} total opens)`
                    : ""}
                </span>
              </span>
            ) : emailInfo?.isOpened === false ? (
              <span className="flex items-center">Not Opened</span>
            ) : (
              ""
            )}
          </span>
        </div>
      ) : (
        <></>
      )}

      {emailInfo?.status ? (
        <div>
          <span className="mt-1" title={emailInfo?.status}>
            <b>Status:</b>{" "}
            <label className="capitalize">{`${emailInfo?.status} ${
              emailInfo?.status === "not delivered"
                ? emailInfo?.reason
                  ? ` (${emailInfo?.reason})`
                  : ""
                : ""
            } `}</label>
          </span>
        </div>
      ) : (
        <></>
      )}

      <div className="flex text-white space-x-1">
        <label className="font-bold">Subject:</label>
        <label className="flex">{emailInfo?.subject || "-"}</label>
      </div>

      <hr className="mt-10 border-white min-w-[100px]" />
      <div
        className="text-justify p-1"
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: decodeHTMl(emailInfo?.body) }}
      ></div>
    </div>
  );
};

export default EmailToolTipContent;
