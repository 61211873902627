import moveIcon from "Assets/icons/moveIcon.svg";
import Skeleton from "Components/Common/Fields/Skeleton";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import Select from "Components/Drips/DripInputs/Select";
import { leadHistoryfilterOption } from "Components/Leads/assetData";
import { isValidArray, showErrorMsg, showSuccessMsg } from "Modules/util";
import {
  useDeleteHistoryMutation,
  useGetHistoryByLeadIdQuery,
} from "Redux/Leads/History/history";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TodoHistoryCard from "./TodoHistoryCard";

const History = ({
  lead,
  drag,
  canDrop,
  isDragging,
  setIsOpenNestModal = () => {},
}) => {
  //State for hide/show todo delete modal
  const userPermissions = useSelector((state) => state?.user?.permission);
  const [deleteHistory] = useDeleteHistoryMutation(); // Mutation hook for delete a history
  const [isDeleteHistoryModal, setIsDeleteHistoryModal] = useState(false);
  const [id, setId] = useState(); //State for setting todo id
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const historyContainerRef = useRef(null);

  const leadId = lead?.leadResponse?.[0]?._id;

  const [SelectType, setSelectType] = useState("All");
  const [historyData, setHistoryData] = useState();
  const [filterData, setFilterData] = useState({
    module:
      SelectType === "All"
        ? ["Task", "Lead", "Call", "Email", "Note", "Drip", "SMS"]
        : SelectType,
  });

  // lead wise history list api
  const {
    data: getHistoryByLeadId,
    isLoading: isHistoryListLoading,
    refetch: HistoryListRefetch,
  } = useGetHistoryByLeadIdQuery(
    { _id: leadId, ...filterData },
    { skip: leadId ? false : true }
  );

  useEffect(() => {
    let historyData =
      getHistoryByLeadId?.data?.getHistoryByLeadId?.historiesResponse;
    historyData = historyData?.map((item) => ({
      ...item,
      note:
        item?.module === "Email" ? item?.note?.split("\n\n")?.[0] : item?.note,
    }));
    if (!isHistoryListLoading) {
      if (isValidArray(historyData)) {
        setHistoryData(historyData);
      } else {
        setHistoryData([]);
      }
    }
  }, [getHistoryByLeadId]);

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      deleteHistoryModal: isDeleteHistoryModal,
    }));
  }, [isDeleteHistoryModal]);

  // Function to handle delete History
  function handleDeleteHistory() {
    let payload = {
      leadId: leadId,
      historyId: id,
    };
    // Delete history API call (mutation)
    deleteHistory(payload)
      .then((response) => {
        if (response?.data?.data?.deleteHistory?.type === "success") {
          showSuccessMsg(response?.data?.data?.deleteHistory?.message);
        } else if (
          response?.data?.data?.deleteHistory?.type === "error" ||
          response?.data?.errors?.[0]?.message
        ) {
          showErrorMsg(
            response?.data?.data?.deleteHistory?.message ||
              response?.data?.errors?.[0]?.message
          );
        }
      })
      .catch((error) => showErrorMsg("Error occurred while Deleting History."));
    setIsDeleteHistoryModal(false);
  }
  // Function to handle history filter change
  const historyFilterChange = (value) => {
    setIsLoading(true); // Set loading state to true during API call
    setSelectType(value); // Update the SelectType state with the new value
    if (value === "All") {
      setFilterData((prev) => ({
        ...prev,
        module: ["Task", "Lead", "Call", "Email", "Note", "Drip", "SMS"],
      }));
    } else {
      setFilterData((prev) => ({ ...prev, module: [value] }));
    }
    setIsLoading(false); // Reset loading state after state update
  };

  useEffect(() => {
    // Added minor delay which gives time to load the DOM
    const scrollTimeout = setTimeout(() => {
      if (historyContainerRef?.current) {
        historyContainerRef.current.scrollTop = 0; // Scroll to the top
      }
    }, 100);
    return () => clearTimeout(scrollTimeout);
  }, [historyData]);

  return (
    <>
      <div
        className="w-full sm:!min-w-[475px] shadow-md rounded-2xl"
        style={{
          opacity: isDragging ? 0.4 : 1,
        }}
      >
        <div
          className={`bg-indigo-400 !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
            canDrop && "!bg-indigo-300 opacity-85"
          }`}
        >
          <div className="space-x-2 flex items-center ">
            <div className="flex">
              <div className="bg-white w-0.5"></div>
              <div className="text-white pl-[7px] font-medium">History</div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            <Select
              className="w-[90px] !h-[25px] !py-0 rounded-lg"
              options={leadHistoryfilterOption}
              selectOption={false}
              value={SelectType}
              onChange={(e) => historyFilterChange(e.target?.value)}
            />
            <div
              className="bg-indigo-600 rounded-md flex items-center p-1.5 h-7 w-7 cursor-move"
              ref={drag}
            >
              <img
                alt="edit-icon"
                src={moveIcon}
                className="h-4 w-4 rounded-md filter invert brightness-0"
                title={"Move Segment"}
              />
            </div>
          </div>
        </div>
        <div
          className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto scrollbar-indigo !text-xs py-2 px-1 ${
            canDrop && "!bg-indigo-300 opacity-85"
          }`}
          ref={historyContainerRef}
        >
          {isLoading || isHistoryListLoading ? (
            <Skeleton counter={5} height={"60px"} className="my-3" />
          ) : (
            <>
              {isValidArray(historyData) ? (
                historyData?.map((history, index) => (
                  <TodoHistoryCard
                    key={index}
                    history={history}
                    setId={setId}
                    index={index}
                    userPermissions={userPermissions}
                    setIsDeleteHistoryModal={setIsDeleteHistoryModal}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center h-full text-gray-600">
                  <span>No history available.</span>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ConfirmationModal
        isModalOpen={isDeleteHistoryModal}
        setModalOpen={setIsDeleteHistoryModal}
        handleAction={() => handleDeleteHistory(id)}
      />
    </>
  );
};

export default History;
