import React, { useEffect, useState } from "react";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  convertDateToUTC,
  getLocalStorage,
  isValidDate,
  showErrorMsg,
  showLeadDateTimeFormat,
} from "Modules/util";
import { MinusIcon } from "@heroicons/react/24/outline";
import { useEditLeadMutation } from "Redux/Leads/lead";

// Extend dayjs with required plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const LeadTableCustomDatePicker = (props) => {
  const {
    label = "",
    formik = "",
    name,
    placeholder = "Select Date",
    initialValue,
    className = "",
    setNewSelectedOption = () => {},
    isDisabled = false,
    id = "",
    isLeadTableRegisterDate = false,
    isDateEditable = true,
    cellInfo = "",
    updatedRegisterDate = "",
    setUpdatedRegisterDate = () => {},
    registerDate = "",
    setRegisterDate = () => {},
    setIsDateValid = () => {},
    stopEditing = () => {},
  } = props;

  const timeZone = getLocalStorage("timezone");

  const [isOpen, setIsOpen] = useState(true);
  const [editLead] = useEditLeadMutation();

  // State to store initial date
  const [startDate, setStartDate] = useState(() => {
    if (initialValue) {
      return dayjs(initialValue).tz(timeZone);
    }
    return null; // Default value if initialValue is not provided
  });

  const handleChange = (dateValue) => {
    setStartDate(dateValue);
    setRegisterDate(dateValue);

    let formattedDate = "";
    if (isValidDate(dateValue)) {
      formattedDate = convertDateToUTC(dateValue);
      setIsDateValid(true);
    } else {
      setIsDateValid(false);
    }

    if (isLeadTableRegisterDate) {
      if (!dateValue || !dateValue.isValid()) {
        showErrorMsg("Please Select Valid Date");
        return;
      }
    } else {
      if (dateValue && isValidDate(dateValue)) {
        formik?.setFieldValue(name, formattedDate);
      } else {
        formik.setErrors({ [name]: "Please Select Valid Date" });
      }
    }
  };

  useEffect(() => {
    if (isDateEditable && isLeadTableRegisterDate) {
      setUpdatedRegisterDate(() => cellInfo?.data);
    }
  }, [isDateEditable]);

  useEffect(() => {
    if (
      !isDateEditable &&
      isLeadTableRegisterDate &&
      cellInfo?.data?._id === updatedRegisterDate?._id
    ) {
      if (!!registerDate) {
        isValidDate(registerDate)
          ? setNewSelectedOption({
              _id: id,
              [name]: convertDateToUTC(registerDate),
            })
          : showErrorMsg("Please Select Valid Date");
      }
      setUpdatedRegisterDate("");
      setRegisterDate("");
    }
  }, [isDateEditable]);

  const handleOk = async (date) => {
    if (isValidDate(date)) {
      let payload = {
        _id: id,
        [name]: convertDateToUTC(date),
      };
      const res = await editLead(payload);
      const editLeadResponse = res?.data?.data?.editLead;
      try {
        const { type, message } = editLeadResponse;
        if (type === "error") {
          showErrorMsg(message);
        }
      } catch (error) {
        showErrorMsg("Error occurred while updating register date");
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    stopEditing();
  };

  if (!isDateEditable && isLeadTableRegisterDate) {
    if (initialValue) {
      return (
        <div className={"text-xs mt-3"}>
          <div className="font-semibold text-gray-700">
            {showLeadDateTimeFormat(initialValue)}
          </div>
        </div>
      );
    } else {
      return <MinusIcon className="w-5 h-5" />;
    }
  }

  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>

      {isLeadTableRegisterDate ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={startDate}
            onChange={handleChange}
            onAccept={(date) => handleOk(date)}
            open={isOpen}
            format="MMMM D, YYYY, h:mm A"
            disabled={isDisabled}
            onClose={() => handleClose()}
            slotProps={{
              textField: {
                onClick: () => setIsOpen(true),
                disabled: true,
              },
            }}
            closeOnSelect={false}
            disableFuture
            className={`w-full MyDatePicker rounded outline-none focus:border-gray-800 focus:ring-gray-500 !text-sm ${className}`}
          />
        </LocalizationProvider>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            value={startDate}
            onChange={handleChange}
            format="MMMM D, YYYY, h:mm A"
            disabled={isDisabled}
            closeOnSelect={false}
            disableFuture
            slotProps={{
              textField: {
                disabled: true,
              },
            }}
            className={`w-full MyDatePicker rounded outline-none focus:border-gray-800 focus:ring-gray-500 !text-sm ${className}`}
          />
        </LocalizationProvider>
      )}

      {formik?.errors?.[name] && (
        <div className="text-red-500 text-sm">{formik?.errors[name]}</div>
      )}
    </div>
  );
};

export default LeadTableCustomDatePicker;
