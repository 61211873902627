export const leadType = [
  { value: "Buyer", label: "Buyer" },
  { value: "Seller", label: "Seller" },
  { value: "Renter", label: "Renter" },
];
export const leadTypeShort = [
  {
    value: "Buyer",
    label: (
      <span className="py-[1px] opacity-80 px-1 h-min border border-gray-300 rounded-sm font-medium text-blue-500">
        B
      </span>
    ),
  },
  {
    value: "Seller",
    label: (
      <span className="py-[1px] opacity-80 px-1 h-min border border-gray-300 rounded-sm font-medium text-red-500">
        S
      </span>
    ),
  },
  {
    value: "Renter",
    label: (
      <div>
        <span className="py-[1px] opacity-80 px-1 h-min border border-gray-300 rounded-sm font-medium text-gray-500">
          R
        </span>
      </div>
    ),
  },
];
export const requestedReview = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "will not request", label: "Will Not Request" },
];
export const AddedToSocailMedia = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
  { value: "don't have social media", label: "Don't Have Social Media" },
];

export const variablesArray = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "lender",
  "tags",
  "categories",
  "agentFirstName",
  "agentLastName",
  "companyName",
  "emailSignature",
  "unsubscribeLink",
];
export const withoutUnSubscribeLinkVariablesArray = [
  "firstName",
  "lastName",
  "email",
  "phone",
  "lender",
  "tags",
  "categories",
  "agentFirstName",
  "agentLastName",
  "companyName",
  "emailSignature",
  // "unsubscribeLink",
];
