import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a userAuth object using serverApi.injectEndpoints
const drip = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getDripsList: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getDripsList(payload),
        };
      },
      providesTags: ["Drip"],
    }),
    getDrip: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getDrip(payload),
        };
      },
      providesTags: ["Drip"],
    }),
    addDrip: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.addDrip(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    editDrip: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.editDrip(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    getNodesAndEdgesList: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getNodesAndEdges(payload),
        };
      },
      providesTags: ["NodesAndEdges","DripByLeadId"],
    }),
    addNodesAndEdges: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.addNodesAndEdges(payload),
        };
      },
      invalidatesTags: ["NodesAndEdges", "Drip"],
    }),
    deleteNodesAndEdges: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.deleteNodesAndEdges(payload),
        };
      },
      invalidatesTags: ["NodesAndEdges"],
    }),
    EditNodeMailMessage: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.editNodeMailMessage(payload),
        };
      },
      invalidatesTags: ["NodesAndEdges", "EmailTemplateList"],
    }),
    changeDripStatus: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.changeDripStatus(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    scheduleDripLeads: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.scheduleDripLeads(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    sentScheduleDripLeads: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.sentScheduleDripLeads(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    nodeWiseTemplate: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.nodeWiseTemplate(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    removeLeadFromDrips: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.removeLeadFromDrips(payload),
        };
      },
      invalidatesTags: ["HistoryByLeadId"],
    }),
    addPrompt: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.addPrompt(payload),
        };
      },
    }),
    getPrompt: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getSingleOpenAI(payload),
        };
      },
    }),
    deleteLeadFromDrips: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.deleteLeadFromDrips(payload),
        };
      },
      invalidatesTags: ["HistoryByLeadId"],
    }),
    createNodeFromAI: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.createNodeFromAI(payload),
        };
      },
    }),
    createPreviewForAITemplates: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.createPreviewForAITemplates(payload),
        };
      },
    }),
    PreviewForAINode: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.PreviewForAINode(payload),
        };
      },
    }),
    DripDelete: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.DripDelete(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    cloneDrip: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.DripClone(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    editDripSuggestion: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.EditDripSuggestion(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
    updateDripFrequency: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.updateDripFrequency(payload),
        };
      },
      invalidatesTags: ["Drip"],
    }),
  }),

  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useGetDripsListQuery,
  useGetDripQuery,
  useAddDripMutation,
  useEditDripMutation,
  useGetNodesAndEdgesListQuery,
  useAddNodesAndEdgesMutation,
  useDeleteNodesAndEdgesMutation,
  useEditNodeMailMessageMutation,
  useChangeDripStatusMutation,
  useScheduleDripLeadsQuery,
  useSentScheduleDripLeadsQuery,
  useNodeWiseTemplateQuery,
  useRemoveLeadFromDripsMutation,
  useAddPromptMutation,
  useGetPromptMutation,
  useDeleteLeadFromDripsMutation,
  useCreateNodeFromAIMutation,
  useCreatePreviewForAITemplatesMutation,
  usePreviewForAINodeMutation,
  useDripDeleteMutation,
  useCloneDripMutation,
  useEditDripSuggestionMutation,
  useUpdateDripFrequencyMutation
} = drip;
