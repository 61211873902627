export const gqlBody = {
  getDripByLeadId(payload) {
    return {
      query: `query GetDripByLeadId($input: inputSingleLead) {
        getDripByLeadId(input: $input) {
          type
          message
          code
          singleDripResponse {
            dripsResponse {
              _id
              leadId
              dripId
              dripTitle
              dripStatus
              isPlay
              leadDripStatus
              isTerminated
              dripSortOrder
              skipNodes
              scheduledNodes
            }
            dripStepResponse {
              nodeId
              templateType
              scheduleTime
              status
              updatedAt
              dripId
              time
              type
              message
              isOpenAI
              skipNodes
              scheduledNodes
              subject
            }
            dripFreqResponse {
              leadId
              dripId
              freqTime
              freqType
            }
          }
        }
      }`,
      variables: { input: payload },
    };
  },
  RescheduleDripLead(payload) {
    return {
      query: `mutation RescheduleDripLead($input: RescheduleDripLeadInput!) {
        rescheduleDripLead(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  DripScheduleTime(payload) {
    return {
      query: `query DripScheduleTime($input: DripScheduleTimeInput) {
        dripScheduleTime(input: $input) {
          scheduleTime
          timeType
          time
          type
          freqTime
          freqType
          isSameFrequency
          firstNodeId
          nodeId
          nodeType
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
  skipCurrentNode(payload) {
    return {
      query: `mutation SkipCurrentNode($input: AddDripPreviewInput) {
        skipCurrentNode(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
};
