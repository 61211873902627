import editIcon from "Assets/icons/editIcon.svg";
import ClosedLeadInfoModal from "Components/Leads/Modal/CloseLeadInfoModal";
import {
  isPermission,
  isValidArray,
  showLeadDateFormat,
  showLeadDateTimeFormat,
} from "Modules/util";
import { useLeadSourceQuery } from "Redux/Leads/lead";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MergeLeadModal from "./MergeLeadModal";
import Tippy from "@tippyjs/react";

export default function LeadActivity(props) {
  const { lead, leadId, setIsOpenAddDeatilsModal, setIsOpenNestModal } = props;
  const [source, setSource] = useState([]);
  const [isClosedLeadModal, setIsClosedLeadModal] = useState(false);
  const [isOpenMergeLeadModal, setIsOpenMergeLeadModal] = useState(false);
  const userPermissions = useSelector((state) => state?.user?.permission);
  const { data: souceData, isSuccess: sourceListIsSuccess } =
    useLeadSourceQuery();

  useEffect(() => {
    if (
      sourceListIsSuccess &&
      isValidArray(souceData?.data?.leadSource?.response)
    ) {
      setSource(
        souceData?.data?.leadSource?.response?.find(
          (source) => source?._id === lead?.leadResponse?.[0]?.source
        )
      );
    }
  }, [souceData, lead?.leadResponse?.[0]?.source]);

  const leadData = {
    source: source?.title ?? "-",
    assignedAgent: lead?.agentResponse?.[0]?.name ?? "-",
    assignLender: lead?.lenderResponse?.[0]?.name ?? "-",
    tags: lead?.tagResponse ?? "-",
    birthDate: lead?.leadResponse?.[0]?.birthDate ?? "",
    birthMonth: lead?.leadResponse?.[0]?.birthMonth ?? "",
    birthYear: lead?.leadResponse?.[0]?.birthYear ?? "",
    address: lead?.leadResponse?.[0]?.address ?? "-",
    closeDate: lead?.leadResponse?.[0]?.closeDate
      ? showLeadDateFormat(lead?.leadResponse?.[0]?.closeDate)
      : "-",
    registeredDate: lead?.leadResponse?.[0]?.registerDate
      ? showLeadDateTimeFormat(lead?.leadResponse?.[0]?.registerDate)
      : "-",
  };
  const leadBirthDay =
    leadData?.birthMonth && leadData?.birthDate && leadData?.birthYear
      ? moment(
          `${leadData?.birthMonth} ${leadData?.birthDate}, ${leadData?.birthYear}`
        )?.format("MMMM Do, YYYY")
      : "";

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      mergeLeadModal: isOpenMergeLeadModal,
    }));
  }, [isOpenMergeLeadModal]);

  return (
    <>
      {isOpenMergeLeadModal && (
        <MergeLeadModal
          isOpen={isOpenMergeLeadModal}
          setIsOpen={setIsOpenMergeLeadModal}
          leadData={lead}

          // leadRefetch={leadRefetch}
        />
      )}
      <ClosedLeadInfoModal
        leadId={leadId}
        leadData={lead?.leadResponse?.[0] ?? []}
        setModalOpen={setIsClosedLeadModal}
        isModalOpen={isClosedLeadModal}
        LeadIsClosed={
          isValidArray(lead?.leadResponse)
            ? lead?.leadResponse[0]?.isClosed
            : false
        }
      />

      <div className="sm:min-w-[475px] w-full shadow-md rounded-2xl">
        <div className="leadDetails-Bluecard-header xs2:h-[40px] xs2:space-y-0 space-y-2 rounded-t-2xl flex items-center justify-between px-4 xxs:flex-no flex-wrap">
          <div className="space-x-2 flex items-center">
            <div className="flex h-auto xs2:mt-0 mt-1">
              <div className="bg-white w-0.5"></div>
              <div className="text-white pl-[7px] font-medium">
                Lead Activity
              </div>
            </div>
          </div>
          <div className="space-x-4 font-semibold flex">
            <span
              className="text-white underline hover:cursor-pointer"
              title="Merge Profile"
              onClick={() => setIsOpenMergeLeadModal(true)}
            >
              Merge Profile
            </span>
            <span
              className="text-white underline hover:cursor-not-allowed"
              title="coming soon"
            >
              Related Profile
            </span>
          </div>
        </div>

        <div className="leadDeatils-card-body sm:h-[200px] rounded-b-2xl pt-3 pb-2 px-4 space-y-[9px] text-xs overflow-y-auto">
          <div className="flex xs3:flex-nowrap flex-wrap">
            <div className=" w-full">
              <div className="text-gray">Date Registered</div>
              <div className="font-bold flex justify-between group">
                <div className="font-bold w-auto">
                  {leadData?.registeredDate}
                </div>
                {isPermission(userPermissions, "edit_leads") && (
                  <span
                    className="invisible group-hover:visible self-end transition-all bg-blue-100 rounded-full border border-blue-400 p-1"
                    onClick={() => setIsOpenAddDeatilsModal(true)}
                  >
                    <img
                      alt="edit-icon"
                      src={editIcon}
                      className="h-3 w-3 hover:cursor-pointer"
                      style={{
                        filter:
                          "invert(33%) sepia(93%) saturate(3228%) hue-rotate(205deg) brightness(98%) contrast(96%)",
                      }}
                      title={"Edit Register Date"}
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="bg-blue-400 h-[40px] w-1.5 mx-2 xs3:inline-block hidden"></div>
            <div className="bg-blue-400 h-[1px] w-full xs3:mx-2 mx-0 xs3:mb-0 mb-1 inline-block xs3:hidden"></div>
            <div className=" w-full">
              <div className="text-gray">Source</div>
              <div className="font-bold flex justify-between group">
                <div className="font-bold">{leadData?.source}</div>
                {isPermission(userPermissions, "edit_leads") && (
                  <span
                    className="invisible group-hover:visible transition-all bg-blue-100 rounded-full border border-blue-400 p-1"
                    onClick={() => setIsOpenAddDeatilsModal(true)}
                  >
                    <img
                      alt="edit-icon"
                      src={editIcon}
                      className="h-3 w-3 hover:cursor-pointer"
                      style={{
                        filter:
                          "invert(33%) sepia(93%) saturate(3228%) hue-rotate(205deg) brightness(98%) contrast(96%)",
                      }}
                      title={"Edit Source"}
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="bg-blue-400 h-[40px] w-1.5 mx-2 xs3:inline-block hidden"></div>
            <div className="bg-blue-400 h-[1px] w-full xs3:mx-2 mx-0 xs3:mb-0 mb-1 inline-block xs3:hidden"></div>
            <div className=" w-full">
              <div className="text-gray">Agent Assigned</div>
              <div className="font-bold flex justify-between group">
                <div className="font-bold truncate">
                  {leadData?.assignedAgent}
                </div>
                {isPermission(userPermissions, "edit_leads") && (
                  <span
                    className="invisible group-hover:visible transition-all bg-blue-100 rounded-full border border-blue-400 p-1"
                    onClick={() => setIsOpenAddDeatilsModal(true)}
                  >
                    <img
                      alt="edit-icon"
                      src={editIcon}
                      className="h-3 w-3 hover:cursor-pointer"
                      style={{
                        filter:
                          "invert(33%) sepia(93%) saturate(3228%) hue-rotate(205deg) brightness(98%) contrast(96%)",
                      }}
                      title={"Edit Agent"}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex xs3:flex-nowrap flex-wrap">
            <div className=" w-full">
              <div className="text-gray">Closing Date</div>
              <div className="font-bold flex justify-between group">
                <div className="font-bold">{leadData?.closeDate}</div>
                {isPermission(userPermissions, "edit_leads") && (
                  <span
                    className="invisible group-hover:visible transition-all bg-blue-100 rounded-full border border-blue-400 p-1"
                    onClick={() => setIsOpenAddDeatilsModal(true)}
                  >
                    <img
                      alt="edit-icon"
                      src={editIcon}
                      className="h-3 w-3 hover:cursor-pointer"
                      style={{
                        filter:
                          "invert(33%) sepia(93%) saturate(3228%) hue-rotate(205deg) brightness(98%) contrast(96%)",
                      }}
                      title={"Edit Closing Date"}
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="bg-blue-400 h-[40px] w-1.5 mx-2 xs3:inline-block hidden"></div>
            <div className="bg-blue-400 h-[1px] w-full xs3:mx-2 mx-0 xs3:mb-0 mb-1 inline-block xs3:hidden"></div>

            <div className=" w-full">
              <div className="text-gray">Birth Date</div>
              <div className="font-bold flex justify-between group">
                <div className="font-bold">
                  {leadBirthDay?.trim() ? leadBirthDay : "-"}
                </div>
                {isPermission(userPermissions, "edit_leads") && (
                  <span
                    className="invisible group-hover:visible transition-all bg-blue-100 rounded-full border border-blue-400 p-1"
                    onClick={() => setIsOpenAddDeatilsModal(true)}
                  >
                    <img
                      alt="edit-icon"
                      src={editIcon}
                      className="h-3 w-3 hover:cursor-pointer"
                      style={{
                        filter:
                          "invert(33%) sepia(93%) saturate(3228%) hue-rotate(205deg) brightness(98%) contrast(96%)",
                      }}
                      title={"Edit Birth Date"}
                    />
                  </span>
                )}
              </div>
            </div>
            <div className="bg-blue-400 h-[40px] w-1.5 mx-2 xs3:inline-block hidden"></div>
            <div className="bg-blue-400 h-[1px] w-full xs3:mx-2 mx-0 xs3:mb-0 mb-1 inline-block xs3:hidden"></div>

            <div className="w-full">
              <div className="text-gray">Lender Assigned</div>
              <div className="font-bold flex justify-between group">
                <div className="font-bold">{leadData?.assignLender}</div>
                {isPermission(userPermissions, "edit_leads") && (
                  <span
                    className="invisible group-hover:visible transition-all bg-blue-100 rounded-full border border-blue-400 p-1"
                    onClick={() => setIsOpenAddDeatilsModal(true)}
                  >
                    <img
                      alt="edit-icon"
                      src={editIcon}
                      className="h-3 w-3 hover:cursor-pointer"
                      style={{
                        filter:
                          "invert(33%) sepia(93%) saturate(3228%) hue-rotate(205deg) brightness(98%) contrast(96%)",
                      }}
                      title={"Edit Lender"}
                    />
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex">
            <div className="text-gray mr-3 mt-1">Tags</div>
            <div className="flex justify-between group">
              <div className="flex font-bold gap-2 max-w-[400px] sm:flex-nowrap flex-wrap pb-1 overflow-x-auto scrollbar-blue mt-1">
                {isValidArray(leadData?.tags)
                  ? leadData?.tags?.map((tag, index) => (
                      <span
                        key={index}
                        className="bg-blue-100 border border-blue-400 rounded !text-xs font-medium text-nowrap flex items-center"
                      >
                        {tag?.title}
                      </span>
                    ))
                  : "-"}
              </div>
              {isPermission(userPermissions, "edit_leads") && (
                <div
                  className="invisible group-hover:visible transition-all bg-blue-100 rounded-full border border-blue-400 p-1 h-6 w-6 ml-2 shrink-0"
                  onClick={() => setIsOpenAddDeatilsModal(true)}
                >
                  <img
                    alt="edit-icon"
                    src={editIcon}
                    className="h-3 w-3 hover:cursor-pointer"
                    style={{
                      filter:
                        "invert(33%) sepia(93%) saturate(3228%) hue-rotate(205deg) brightness(98%) contrast(96%)",
                    }}
                    title={"Edit Tags"}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="flex">
            <div className="w-full max-w-full">
              <div className="text-gray">Address</div>
              <div className="font-bold flex space-x-2 group items-end">
                {leadData?.address ? (
                  <Tippy
                    allowHTML={true}
                    interactive={true}
                    placement={"bottom"}
                    animateFill={true}
                    animation={"scale"}
                    maxWidth={"600px"}
                    content={leadData?.address ? leadData?.address : "-"}
                  >
                    <div className="font-bold w-full max-w-full xs:overflow-hidden xs:line-clamp-1 text-wrap ">
                      {leadData?.address ? leadData?.address : "-"}
                    </div>
                  </Tippy>
                ) : (
                  <div className="font-bold w-full max-w-full overflow-hidden line-clamp-1 ">
                    {"-"}
                  </div>
                )}
                {isPermission(userPermissions, "edit_leads") && (
                  <div
                    className="invisible group-hover:visible transition-all bg-blue-100 rounded-full border border-blue-400 p-1 shrink-0"
                    onClick={() => setIsOpenAddDeatilsModal(true)}
                  >
                    <img
                      alt="edit-icon"
                      src={editIcon}
                      className="h-3 w-3 hover:cursor-pointer"
                      style={{
                        filter:
                          "invert(33%) sepia(93%) saturate(3228%) hue-rotate(205deg) brightness(98%) contrast(96%)",
                      }}
                      title={"Edit Address"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
