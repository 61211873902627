import { convertToUTC, getLocalStorage, showErrorMsg } from "Modules/util";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const CustomDatePicker = (props) => {
  const {
    label = "",
    formik = "",
    name,
    placeholder = "Select Date",
    initialValue,
    isClearable = false,
    className = "",
    isLeadTableClosedDate = false,
    setNewSelectedOption = () => {},
    id = "",
  } = props;
  const timeZone = getLocalStorage("timezone");
  const convertedDate = moment(initialValue).tz(timeZone).format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(convertedDate);

  const handleChange = (date) => {
    setStartDate(date);
    if (isLeadTableClosedDate) {
      if (!date) {
        showErrorMsg("Please select date");
        return;
      }
      setNewSelectedOption({
        _id: id,
        [name]: convertToUTC(date, timeZone),
      });
    } else {
      if (date) {
        formik?.setFieldValue(name, convertToUTC(date, timeZone));
      } else {
        formik?.setFieldValue(name, null);
      }
    }
  };

  return (
    <div className={`space-y-2`}>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <input
        type="date"
        onChangeRaw={(e) => e.preventDefault()}
        showIcon
        value={startDate}
        onChange={(e) => handleChange(e?.target?.value)}
        className={`rounded w-[600px] outline-none focus:border-gray-800 focus:ring-gray-500 text-sm  ${className}`}
        dateFormat="dd/MM/YYYY"
        placeholderText={placeholder}
      />
      {formik?.errors?.[name] && (
        <div className="text-red-500 text-sm">{formik?.errors[name]}</div>
      )}
    </div>
  );
};

export default CustomDatePicker;
