import { Dialog, Transition } from "@headlessui/react";
import InputField from "Components/Common/Fields/InputField";
import MultiSelectField from "Components/Common/Fields/MultiSelectField";
import SelectField from "Components/Common/Fields/SelectField";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import { leadType } from "Components/Common/data";
import {
  PhoneDigitFormat,
  emailCharFormat,
  emailFormat,
  phoneFormat,
} from "Components/Common/formatter";
import { isValidArray } from "Modules/util";
import { Fragment, useEffect, useState } from "react";
import BirthdayInfo from "./BirthdayInfo";
import Email from "./Email";
import Phone from "./Phone";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import CustomDatePicker from "Components/Common/Fields/CustomDatePicker";
import LeadTableCustomDatePicker from "Components/Common/Fields/LeadTableCustomDatePicker";
export default function AddDeatilsModal(props) {
  const {
    lead,
    leadId,
    isOpen,
    setIsOpen,
    agents,
    lenders,
    sourceListOptions,
    categorys,
    tags,
    isFetching,
    formik,
    editLeadSubmit,
    isEditPermisstion,
    setIsEditableOpen,
    isEditableOpen,
    handleModalClick,
    isLeadEditLoading,
    editPhoneEmail,
    getAllCategory,
    setIsOpenNestModal,
    setIsDateValid,
    isDateValid,
  } = props;

  const [isOpenDoubleNestModal, setIsOpenDoubleNestModalState] =
    useState(false); // State to manage the open state of the double-nested modal

  useEscapeClose(setIsOpen, isOpenDoubleNestModal); // Custom hook to close a modal when the Escape key is pressed.

  useEffect(() => {
    if (!isOpen) {
      formik?.resetForm();
    }
  }, [isOpen]);

  const isClosedCategory =
    getAllCategory?.data?.listCategory?.response?.find((item) => item?.close)
      ?._id === formik?.values?.category;

  if (!isOpen) {
    return null;
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[99]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-6xl transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg pb-2 border-b-2 font-medium leading-6 text-gray-900 flex justify-between items-center"
                >
                  Edit Details
                  <button
                    onClick={() => setIsOpen(false)}
                    className="focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500 hover:text-gray-700 transition duration-300"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.293 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </Dialog.Title>
                <div className="xs:px-8 px-0 pt-6 mb-2">
                  <div className="flex md1:space-x-4 space-x-0 md1:flex-nowrap flex-wrap">
                    {/* <div> */}
                    <div className="space-y-2.5 rounded-md xs:min-w-[340px] md1:max-w-[400px] w-full p-2">
                      <div>
                        <InputField
                          id="firstName"
                          label="First Name"
                          name="firstName"
                          formik={formik}
                          type="text"
                          onChange={formik.handleChange}
                          autoComplete="first name"
                          placeholder="Enter First Name"
                          disabled={!isEditPermisstion}
                        />
                      </div>
                      <div>
                        <InputField
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          formik={formik}
                          type="text"
                          onChange={formik.handleChange}
                          autoComplete="last name"
                          placeholder="Enter Last Name"
                          disabled={!isEditPermisstion}
                        />
                      </div>
                      <div>
                        <label className="text-xs text-gray-900 leading-6 font-medium">
                          Phone
                        </label>
                        <Phone
                          id="phone"
                          name="phone"
                          formik={formik}
                          isSubmit={true}
                          format={phoneFormat}
                          type={PhoneDigitFormat}
                          handleSubmit={editPhoneEmail}
                          disabled={!isEditPermisstion}
                          isFetching={isFetching}
                          setIsEditableOpen={setIsEditableOpen}
                          isEditableOpen={isEditableOpen}
                          handleModalClick={handleModalClick}
                          initialValues={
                            isValidArray(lead?.leadResponse)
                              ? lead?.leadResponse[0]?.phone
                              : []
                          }
                          phoneList={lead?.leadResponse?.[0]?.contact ?? []}
                          showTags={false}
                          leadId={leadId}
                          isModal={true}
                          setIsOpenNestModal={setIsOpenNestModal}
                          setIsOpenDoubleNestModal={
                            setIsOpenDoubleNestModalState
                          }
                        />
                      </div>
                      <div>
                        <label className="text-xs text-gray-900 leading-6 font-medium">
                          Email
                        </label>
                        <Email
                          id="email"
                          name="email"
                          format={emailFormat}
                          formik={formik}
                          isSubmit={true}
                          handleSubmit={editPhoneEmail}
                          type={emailCharFormat}
                          disabled={!isEditPermisstion}
                          isFetching={isFetching}
                          setIsEditableOpen={setIsEditableOpen}
                          isEditableOpen={isEditableOpen}
                          handleModalClick={handleModalClick}
                          initialValues={
                            isValidArray(lead?.leadResponse)
                              ? lead?.leadResponse[0]?.email
                              : []
                          }
                          emailList={
                            lead?.leadResponse?.[0]?.emailStatusDetail ?? []
                          }
                          showTags={false}
                          leadId={leadId}
                          isModal={true}
                          setIsOpenNestModal={setIsOpenNestModal}
                          setIsOpenDoubleNestModal={
                            setIsOpenDoubleNestModalState
                          }
                        />
                      </div>
                      <BirthdayInfo
                        leadId={leadId}
                        lead={lead}
                        setIsOpenDoubleNestModal={setIsOpenDoubleNestModalState}
                      />
                      <div>
                        <LeadTableCustomDatePicker
                          formik={formik}
                          label="Register Date"
                          className="xs:w-[335px] w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#475361] sm:text-sm sm:leading-6"
                          initialValue={formik?.values?.registerDate}
                          name="registerDate"
                          placeholder="Select Register Date"
                          setIsDateValid={setIsDateValid}
                        />
                      </div>
                      {isClosedCategory && (
                        <div>
                          <CustomDatePicker
                            formik={formik}
                            label="Close Date"
                            className="xs:w-[335px] w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#475361] sm:text-sm sm:leading-6"
                            initialValue={formik?.values?.closeDate}
                            name="closeDate"
                            placeholder="Select Closed Date"
                            isLeadTableClosedDate={false}
                          />
                        </div>
                      )}
                    </div>
                    {/* </div> */}
                    <div className="">
                      <div className="flex gap-4 flex-wrap lg2:flex-nowrap">
                        <div className="mb-2 p-2 rounded-md xs:min-w-[330px] lg2:max-w-[400px] w-full !text-xs">
                          <SelectField
                            formik={formik}
                            label={"Agent"}
                            initialvalue={agents?.find(
                              (option) =>
                                option?.value === formik?.values?.agent
                            )}
                            setFieldValue={formik?.setFieldValue}
                            name="agent"
                            isSearchable={true}
                            options={agents}
                            onBlur={formik?.handleBlur}
                            onChange={formik?.handleChange}
                            placeholder="Select Agent"
                            isDisabled={!isEditPermisstion}
                            labelClassname={"!text-xs"}
                          />
                          <div>
                            <SelectField
                              formik={formik}
                              label={"Lender"}
                              setFieldValue={formik.setFieldValue}
                              name="lender"
                              isSearchable={true}
                              options={lenders}
                              onBlur={formik.handleBlur}
                              // isClearable={true}
                              onChange={formik.handleChange}
                              placeholder="Select Lender"
                              isDisabled={!isEditPermisstion}
                              labelClassname={"!text-xs"}
                            />
                          </div>
                          <div>
                            <SelectField
                              formik={formik}
                              label={"Source"}
                              setFieldValue={formik.setFieldValue}
                              name="source"
                              isSearchable={false}
                              options={sourceListOptions}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              placeholder="Select Source"
                              isDisabled={!isEditPermisstion}
                              labelClassname={"!text-xs"}
                            />
                          </div>
                        </div>
                        <div className="p-2 rounded-md xs:min-w-[330px] lg2:max-w-[400px] w-full">
                          <div>
                            <SelectField
                              formik={formik}
                              options={leadType}
                              name="leadType"
                              label="Lead Type"
                              isSearchable={true}
                              setFieldValue={formik.setFieldValue}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              isDisabled={!isEditPermisstion}
                              placeholder="Select Source"
                              labelClassname={"!text-xs"}
                            />
                          </div>
                          <div>
                            <SelectField
                              formik={formik}
                              label={"Category"}
                              setFieldValue={formik.setFieldValue}
                              name="category"
                              isSearchable={true}
                              options={categorys}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              placeholder="Select Category"
                              isDisabled={!isEditPermisstion}
                              labelClassname={"!text-xs"}
                            />
                          </div>
                          <div>
                            <label className="block text-xs font-medium text-gray-900 mb-2">
                              Tags
                            </label>
                            <MultiSelectField
                              formik={formik}
                              initialvalue={lead?.tagResponse}
                              label={"Tags"}
                              isOpen={true}
                              isSearchable={true}
                              name="tags"
                              options={tags}
                              placeholder="Select Tags"
                              isDisabled={!isEditPermisstion}
                              labelClassname={"!text-xs"}
                              isValueNull={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="space-y-3 px-2 ">
                        <InputField
                          id="address"
                          label="Address"
                          name="address"
                          formik={formik}
                          type="text"
                          onChange={formik.handleChange}
                          autoComplete="address"
                          placeholder="Enter Address"
                          disabled={!isEditPermisstion}
                        />
                        <TextArea
                          id="description"
                          label="Description"
                          name="description"
                          formik={formik}
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="description"
                          placeholder="Enter Description"
                          rows={3}
                          disabled={!isEditPermisstion}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-8 pt-5 sm:mt-4 sm:flex sm:flex-row-reverse sm:space-y-0 space-y-2">
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Submit"}
                      action={() => editLeadSubmit(formik?.values)}
                      type={"submit"}
                      loading={isLeadEditLoading}
                      isDisable={isDateValid ? !formik.dirty : !isDateValid}
                    />
                    <button
                      type="button"
                      className=" w-full justify-center rounded bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        setIsOpen(false);
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
