import moveIcon from "Assets/icons/moveIcon.svg";
import Button from "Components/Common/Buttons/Button";
import Skeleton from "Components/Common/Fields/Skeleton";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import AddTaskForm from "Components/Tasks/AddTaskForm";
import {
  isPermission,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { useGetTaskByLeadIdQuery } from "Redux/Leads/Task/task";
import { useDeleteTaskMutation, useListTodoQuery } from "Redux/Task/TaskQuery";
import { useAgentAndAgentManagerListQuery } from "Redux/user/user";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import TaskCard from "./TaskCard";

export default function Tasks(props) {
  const {
    lead,
    setCompletedTaskList,
    completedTaskList,
    isModal,
    isCompleteTaskInProcess,
    setIsCompleteTaskInProcess,
    isOpen,
    setDeletedTask,
    agents,
    drag,
    canDrop,
    preview,
    setIsOpenNestModal = () => {},
    handleCompletedTasks = () => {},
  } = props;

  const userPermissions = useSelector((state) => state?.user?.permission); // Extracting user permissions from the Redux state using the 'useSelector' hook
  const [addTaskModal, setaddTaskModal] = useState(false);
  const [editTaskId, setEditTaskId] = useState(""); // State for tracking task to be edited
  const [isDeleteModal, setIsDeleteModal] = useState(false); // State for controlling delete confirmation modal
  const [deleteTaskId, setDeleteTaskId] = useState(""); // State for tracking task to be deleted
  const taskContainerRef = useRef(null);
  const [deleteTask] = useDeleteTaskMutation(); // Delete Task Mutation
  const [latestCompletedTask, setLatestCompetedTask] = useState("");

  const [taskList, setTaskList] = useState([]);
  const leadId = lead?.leadResponse?.[0]?._id;

  // lead wise task list api
  const {
    data: getTasksByLeadId,
    isLoading: isTaskListLoading,
    refetch: taskListRefetch,
  } = useGetTaskByLeadIdQuery(
    {
      _id: leadId,
    },
    { skip: leadId ? false : true }
  );

  useEffect(() => {
    let taskListData = getTasksByLeadId?.data?.getTaskByLeadId?.taskResponse;
    if (!isTaskListLoading) {
      if (isValidArray(taskListData)) {
        setTaskList(taskListData);
      } else {
        setTaskList([]);
      }
    }
  }, [getTasksByLeadId]);

  // useEffect for close nested modal on Escape key press
  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      addTaskModal: addTaskModal,
      deleteTaskModal: isDeleteModal,
    }));
  }, [addTaskModal, isDeleteModal]);

  const {
    data: agentAndAgentManagerList,
    isSuccess: agentAndAgentManagerListIsSuccess,
  } = useAgentAndAgentManagerListQuery();

  const {
    data: getListTodo,
    isSuccess: isListSuccess,
    refetch: refetchTodoList,
  } = useListTodoQuery();

  // Effect to scroll to the bottom when Task are updated
  const refs = taskList?.reduce((task, index) => {
    task[index?._id] = React.createRef();
    return task;
  }, {});

  // Async function to handle the deletion of a task
  async function handleDeleteTask() {
    try {
      // Making an API call to delete the task
      const res = await deleteTask({ _id: deleteTaskId });
      const deleteTaskRes = res?.data?.data?.deleteTask;
      if (deleteTaskRes) {
        const { type, message } = deleteTaskRes; // Showing success or error message based on the response
        if (type === "success") {
          showSuccessMsg(message);
          if (isModal) {
            setDeletedTask(deleteTaskId);
          }
        } else if (type === "error") {
          showErrorMsg(message);
        }
      }
      taskListRefetch(); // Refetching tasks list after deletion
    } catch (error) {
      showErrorMsg("Error deleting Task");
    }
    // Closing the delete confirmation modal and resetting task id
    setIsDeleteModal(false);
    setDeleteTaskId("");
  }
  return (
    <>
      {isDeleteModal && (
        <ConfirmationModal
          isModalOpen={isDeleteModal}
          setModalOpen={setIsDeleteModal}
          handleAction={() => handleDeleteTask()}
        />
      )}
      {/* Form for adding/editing tasks */}
      <AddTaskForm
        isOpen={addTaskModal}
        setIsOpen={setaddTaskModal}
        editTaskId={editTaskId}
        refetch={taskListRefetch}
        isLeadClear={false}
        lead={lead}
        listTodosState={{ getListTodo, isListSuccess, refetchTodoList }}
        agentAndAgentManagerState={{
          agentAndAgentManagerList,
          agentAndAgentManagerListIsSuccess,
        }}
        // lead={
        //   isValidArray(lead?.leadResponse)
        //     ? {
        //         ...lead?.leadResponse[0],
        //         agent: isValidArray(lead?.agentResponse)
        //           ? lead?.agentResponse[0]
        //           : [],
        //       }
        //     : ""
        // }
        fromLeadDeatils={true}
      />

      <div
        className="w-full sm:!min-w-[475px] shadow-md rounded-2xl"
        ref={preview}
      >
        <div
          className={`bg-red-400 !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
            canDrop && "!bg-red-300 opacity-85"
          }`}
        >
          <div className="space-x-2 flex items-center ">
            <div className="flex">
              <div className="bg-white w-0.5"></div>
              <div className="text-white pl-[7px] font-medium">Tasks</div>
            </div>
          </div>
          <div className="flex space-x-3">
            {isPermission(userPermissions, "add_task") && (
              <Button
                label={"Add"}
                title={"Add Task"}
                className="py-1 px-3 bg-red-600 text-white shadow-sm shadow-gray-300 text-sm font-medium rounded-lg"
                action={() => {
                  setaddTaskModal(true);
                  setEditTaskId("");
                }}
              />
            )}
            <div
              className="bg-red-600 rounded-md flex items-center px-1.5 h-7 w-7 cursor-move"
              ref={drag}
            >
              <img
                alt="edit-icon"
                src={moveIcon}
                className="h-4 w-4 rounded-md filter invert brightness-0"
                title={"Move Segment"}
              />
            </div>
          </div>
        </div>
        <div
          className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto scrollbar-red space-y-3 py-2 px-1 ${
            canDrop && "!bg-red-300 opacity-85"
          }`}
          ref={taskContainerRef}
        >
          {isTaskListLoading ? (
            <Skeleton counter={5} height={"60px"} className="my-3" />
          ) : isValidArray(taskList) ? (
            taskList?.map((task, key) => {
              return (
                <div key={key}>
                  <TaskCard
                    task={task}
                    refetchLead={taskListRefetch}
                    setDeleteTaskId={setDeleteTaskId}
                    setEditTaskId={setEditTaskId}
                    userPermissions={userPermissions}
                    setaddTaskModal={setaddTaskModal}
                    setIsDeleteModal={setIsDeleteModal}
                    setCompletedTaskList={setCompletedTaskList}
                    completedTaskList={completedTaskList}
                    isModal={isModal}
                    isCompleteTaskInProcess={isCompleteTaskInProcess}
                    setIsCompleteTaskInProcess={setIsCompleteTaskInProcess}
                    isOpen={isOpen}
                    refs={refs}
                    agents={agents}
                    latestCompletedTask={latestCompletedTask}
                    setLatestCompetedTask={setLatestCompetedTask}
                    lead={lead}
                    handleCompletedTasks={handleCompletedTasks}
                  />
                </div>
              );
            })
          ) : (
            <div className="flex justify-center h-full text-gray-600 text-xs">
              <span className="self-center">No tasks available.</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
