import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import TextArea from "Components/Common/Fields/TextArea";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import CkEditor from "Components/Common/TextEditor/CkEditor";
import Select from "Components/Drips/DripInputs/Select";
import { useFormik } from "formik";
import { sentAfterOptions } from "Modules/data";
import {
  getLocalStorage,
  handleModalClose,
  hasMeaningfulContent,
  isValidArray,
  isValidObject,
  removeLocalStorageItem,
  replaceNewlinesWithBR,
  setLocalStorage,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { Fragment, useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";
import { useGetDripQuery, useGetNodesAndEdgesListQuery } from "Redux/Drip/drip";
import {
  useAddDripPreviewMutation,
  useGenerateOpenAIDripResponseMutation,
  useGetLeadQuery,
  useScheduleDripLeadTaskMutation,
} from "Redux/Leads/lead";
import * as Yup from "yup";

export default function AssignAISuggestedDripModal(props) {
  const {
    isOpen,
    setIsOpen,
    leadId,
    setIsAISuggestedDripApproveModal = () => {},
    refetch = () => {},
    selectedDrip,
    scheduleDripData,
    setIsModifyDripFlowModal = () => {},
    setPreviousDripFlowData = () => {},
    setSelectedDrip = () => {},
    savedNodeTime,
    savedFreqTime,
    flushData = () => {},
  } = props;
  useEscapeClose(setIsOpen, false, handleModalClose); // Custom hook to close a modal when the Escape key is pressed.

  const [node, setNode] = useState();
  const [fieldValues, setFieldValues] = useState({
    time: "",
    type: "",
  });

  const quillRef = useRef(null);
  let savedPreview = getLocalStorage("suggestedDripSavedPreview") || `{}`;

  const [generateAddDripPreview, { isLoading: isAddDripPreviewLoading }] =
    useAddDripPreviewMutation();

  const [aiSuggestedDripAssign, { isLoading: isAiSuggestedDripAssign }] =
    useScheduleDripLeadTaskMutation();

  const [
    generateOpenAIDripPreview,
    { isLoading: isGenerateOpenAIDripPreviewLoading },
  ] = useGenerateOpenAIDripResponseMutation();

  const { data: getDrip } = useGetDripQuery(
    {
      id: selectedDrip?.id,
    },
    {
      skip: !selectedDrip?.id ? true : false,
    }
  );

  const { data: getNodesAndEdges, isLoading: isNodeAndedgesLoading } =
    useGetNodesAndEdgesListQuery(
      {
        id: selectedDrip?.id,
      },
      {
        skip: !selectedDrip?.id ? true : false,
      }
    );

  // Fetching lead data using a GraphQL query
  const {
    data: getLead,
    isLoading,
    isSuccess: isLeadSuccess,
  } = useGetLeadQuery(
    { _id: leadId },

    {
      skip: leadId ? true : false,
    }
  );

  useEffect(() => {
    if (isOpen) {
      setIsModifyDripFlowModal(false);
    }
  }, [isOpen]);

  // Extracting lead information
  let lead = isValidArray(getLead?.data?.lead?.response)
    ? getLead?.data?.lead?.response?.[0]
    : [];

  function checkDripTimeChange(obj1, obj2) {
    return obj1?.isDripTimeChange === true || obj2?.isDripTimeChange === true;
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dripId: selectedDrip?.id,
      isDripTimeChange: false,
      subject: getSubject(),
      message: getMessagePreview(),
    },
    validationSchema: Yup.object().shape({
      dripId: Yup.string().required("Please Select Drip"),
      message: Yup.string()
        .notOneOf(["", "<p><br></p>"], "Preview Message Is Required")
        .test("hasText", "Preview Message Is Required", (val) => {
          if (!val) return false;

          // Remove empty tags like <p></p> or <p><br></p>
          const cleanedVal = val
            .replace(/<p>\s*<\/p>/g, "")
            .replace(/<p>\s*<br\s*\/?>\s*<\/p>/g, "")
            .trim();

          // Check if the cleaned HTML contains meaningful content
          return hasMeaningfulContent(cleanedVal);
        })
        .required("Preview Message Is Required"),
      subject:
        node?.type === "mail" && Yup.string().required("Subject Is Required"),
    }),
    onSubmit: async (values) => {
      let payload = {
        ...scheduleDripData,
        isFilter: false,
        Filter: {},
        dripId: selectedDrip?.id,
        isSelectedAll: false,
        leadId: leadId,
        message: values?.message,
        lastDripId: lead?.leadResponse?.[0]?.lastDripId ?? null,
        ...(values?.subject && { subject: values?.subject }),
        ...fieldValues,
        ...(savedFreqTime?.time && { freqTime: savedFreqTime?.time }),
        ...(savedFreqTime?.type && { freqType: savedFreqTime?.type }),
        isDripTimeChange: checkDripTimeChange(fieldValues, savedFreqTime),
      };

      if (!isLoading) {
        try {
          const res = await aiSuggestedDripAssign(payload);
          const scheduleDripLeads = res?.data?.data?.scheduleDripLeadTask;
          if (isValidObject(scheduleDripLeads)) {
            const { type, message } = scheduleDripLeads;
            if (type === "success") {
              showSuccessMsg(message);
              removeLocalStorageItem("suggestedDripSavedPreview");
            } else if (type === "error") {
              showErrorMsg(message);
            }
          } else {
            showErrorMsg("Error occurred while assign lead!");
          }
        } catch (error) {
          showErrorMsg("Error occurred while assign lead");
        }
      } else {
        showErrorMsg("Error occurred while assign lead");
      }
      setIsOpen(false);
      setIsAISuggestedDripApproveModal(false);
      refetch();
      flushData();
    },
  });

  useEffect(() => {
    if (!isNodeAndedgesLoading) {
      let nodes = getNodesAndEdges?.data?.nodeAndEdgesDetails?.nodesData;
      let node = {};
      if (isValidObject(scheduleDripData)) {
        node = nodes?.filter(
          (node) => node?.id === scheduleDripData?.scheduledNodes?.[0]
        )?.[0];
      } else {
        node = nodes?.[0];
      }

      if (nodes?.[0]?.id === scheduleDripData?.scheduledNodes?.[0]) {
        if (savedNodeTime?.id === node?.id) {
          const { id, ...restInfo } = savedNodeTime;
          setFieldValues({
            ...restInfo,
          });
        } else {
          setFieldValues({
            time: node?.data?.time,
            type: node?.data?.type,
            isDripTimeChange: false,
          });
        }
      } else {
        if (isValidObject(savedFreqTime)) {
          setFieldValues({
            time: savedFreqTime?.time,
            type: savedFreqTime?.type,
            isDripTimeChange: savedFreqTime?.isDripTimeChange ?? false,
          });
        } else {
          setFieldValues({
            time: node?.data?.time,
            type: node?.data?.type,
            isDripTimeChange: false,
          });
        }
      }
      setNode(node);
    }
  }, [getNodesAndEdges, scheduleDripData]);

  //manage sent after time on change of time type ( minutes , hour , days)
  function manageSentAfterTime(type) {
    let time = "1";
    if (type === "minutes" && fieldValues?.time === "0") {
      time = fieldValues?.time;
    }
    setFieldValues({
      ...fieldValues,
      type: type,
      time: time,
      isDripTimeChange: validateTimeChange(time, type),
    });
  }

  // Validating Time Change
  function validateTimeChange(time, type) {
    if (type === node?.data?.type && time === node?.data?.time) {
      return false;
    } else {
      return true;
    }
  }

  function setInput(inputValue) {
    inputValue = Number(inputValue);

    if (fieldValues?.type === "hours" && inputValue <= 24) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "hours"
        ),
      });
    }
    if (fieldValues?.type === "days" && inputValue <= 100) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "days"
        ),
      });
    }
    if (fieldValues?.type === "minutes" && inputValue <= 60) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "minutes"
        ),
      });
    }
    if (fieldValues?.type === "weeks" && inputValue <= 99) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "weeks"
        ),
      });
    }
    if (fieldValues?.type === "months" && inputValue <= 99) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "months"
        ),
      });
    }
    if (fieldValues?.type === "years" && inputValue <= 10) {
      setFieldValues({
        ...fieldValues,
        time: inputValue.toString(),
        isDripTimeChange: validateTimeChange(
          inputValue.toString() || "0",
          "years"
        ),
      });
    }
  }

  async function generatDripPreview() {
    let payload = {
      dripId: selectedDrip?.id,
      leadId: leadId,
      nodeId: scheduleDripData?.scheduledNodes?.[0],
    };
    try {
      const res = await generateAddDripPreview(payload);
      const DripPreview = res?.data?.data?.addDripPreview;
      if (isValidObject(DripPreview)) {
        const { type, message, preview, subject } = DripPreview;
        if (type === "success") {
          if (node?.type === "message") {
            formik.setFieldValue("message", preview);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: preview,
            });
          } else {
            formik.setFieldValue("message", replaceNewlinesWithBR(preview));
            formik.setFieldValue("subject", subject);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: replaceNewlinesWithBR(preview),
              subject: subject,
            });
          }
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  async function generateOpenAIDripResponse() {
    let payload = {
      dripId: selectedDrip?.id,
      leadId: leadId,
      nodeId: node?.id,
      nodeType: node?.type,
      isSuggestedDrip: true,
    };
    const res = await generateOpenAIDripPreview(payload);
    const openAIDripPreview = res?.data?.data?.generateOpenAIDripResponse;
    try {
      if (isValidObject(openAIDripPreview)) {
        const { type, message, response, subject } = openAIDripPreview;
        if (type === "success") {
          showSuccessMsg(message);
          if (node?.type === "message") {
            formik?.setFieldValue("message", response);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: response,
            });
          } else {
            formik?.setFieldValue("message", replaceNewlinesWithBR(response));
            formik?.setFieldValue("subject", subject);
            manageLocalStorage({
              nodeId: node?.id,
              node: node?.type,
              preview: replaceNewlinesWithBR(response),
              subject: subject,
            });
          }
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while generating preview!");
      }
    } catch (error) {
      showErrorMsg("Error occurred while generating preview");
    }
  }

  useEffect(() => {
    let preview = JSON.parse(savedPreview) || `{}`;
    if (isValidObject(node)) {
      if (isOpen && (!isValidObject(preview) || node?.id !== preview?.nodeId)) {
        if (isValidObject(node?.data)) {
          removeLocalStorageItem("suggestedDripSavedPreview");
          if (node?.data?.isOpenAI) {
            generateOpenAIDripResponse();
          } else {
            generatDripPreview();
          }
        }
      } else return;
    }
  }, [isOpen, node]);

  function isRegenerateButtonDisable() {
    if (isValidObject(node?.data)) {
      if (node?.data?.isOpenAI) {
        return false;
      } else {
        return true;
      }
    }
  }
  function manageLocalStorage(previewObj) {
    let savedPreview = getLocalStorage("suggestedDripSavedPreview");
    if (!savedPreview) {
      setLocalStorage("suggestedDripSavedPreview", JSON.stringify(previewObj));
    }
  }

  useEffect(() => {
    if (node?.type === "mail") {
      if (formik?.values?.message?.trim() == "<p><br></p>") {
        formik?.setFieldError("message", "Please Enter Mail");
      }
    }
  }, [formik?.values?.message]);

  function handleModalBack() {
    setIsOpen(false);
    setPreviousDripFlowData({ ...scheduleDripData });
    setIsModifyDripFlowModal(true);
  }

  function getSubject() {
    let savedPreviewData = JSON.parse(savedPreview);
    if (isValidObject(savedPreviewData)) {
      return savedPreviewData?.subject;
    } else {
      return node?.subject;
    }
  }

  function getMessagePreview() {
    let savedPreviewData = JSON.parse(savedPreview);
    if (isValidObject(savedPreviewData)) {
      return savedPreviewData?.preview;
    } else {
      return "";
    }
  }

  function isAssignDripDisable() {
    return isAddDripPreviewLoading ||
      isGenerateOpenAIDripPreviewLoading ||
      (node?.type === "mail" &&
        !formik?.values?.subject &&
        !formik?.values?.message)
      ? true
      : false || (fieldValues?.time === "0" && fieldValues?.type !== "minutes");
  }

  useEffect(() => {
    if (!isOpen) {
      formik?.resetForm();
      setNode({});
    }
  }, [isOpen]);

  function handleAssignDrip() {
    if (
      !isGenerateOpenAIDripPreviewLoading ||
      !isAddDripPreviewLoading ||
      !(fieldValues?.time === "0" && fieldValues?.type !== "minutes")
    ) {
      formik?.handleSubmit();
    }
  }

    // Updating saved preview
    useEffect(() => {
      const previewObj = JSON.parse(getLocalStorage("suggestedDripSavedPreview") || `{}`);
      if (isValidObject(previewObj)) {
        if (previewObj?.node === "mail") {
          setLocalStorage(
            "suggestedDripSavedPreview",
            JSON.stringify({
              ...previewObj,
              preview: formik?.values?.message,
              subject: formik?.values?.subject,
            })
          );
        } else if (previewObj?.node === "message") {
          setLocalStorage(
            "suggestedDripSavedPreview",
            JSON.stringify({
              ...previewObj,
              preview: formik?.values?.message,
            })
          );
        }
      }
    }, [formik?.values?.message, formik?.values?.subject]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-5xl border-b-2 py-2 transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5 ">
                <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                  <div className="sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                    >
                      Are you sure you want to add this lead to{" "}
                      {getDrip?.data?.drip?.drip?.title}?
                    </Dialog.Title>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                    onClick={() => {
                      setIsOpen(false);
                      formik.resetForm();
                      setSelectedDrip(null);
                      flushData();
                      removeLocalStorageItem("suggestedDripSavedPreview");
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-5">
                  <div className="sm:flex space-x-2">
                    <label className="pt-2">
                      Here is a preview of the first{" "}
                      {node?.type === "message" ? "text" : node?.type} which
                      will be sent in
                    </label>
                    <div className="">
                      <div className="flex">
                        <input
                          type="text"
                          className="text-right text-sm rounded-l-md w-[50px] h-[38px] ring-0 outline-none border-gray-300 focus:outline-none focus:ring-gray-500 focus:border-gray-500 "
                          value={fieldValues?.time}
                          onChange={(e) => setInput(e.target.value)}
                          min={1}
                          max={
                            fieldValues?.type === "minutes"
                              ? 60
                              : fieldValues?.type === "hours"
                              ? 24
                              : fieldValues?.type === "days"
                              ? 100
                              : fieldValues?.type === "weeks"
                              ? 99
                              : fieldValues?.type === "months"
                              ? 99
                              : 10
                          }
                          maxLength={3}
                        />
                        <Select
                          className="w-full sm:w-[200px] !rounded-r-lg"
                          options={sentAfterOptions}
                          value={fieldValues?.type}
                          onChange={(e) => manageSentAfterTime(e.target.value)}
                        />
                      </div>
                      {fieldValues.time === "0" &&
                        fieldValues.type !== "minutes" && (
                          <span className="text-sm text-start text-red-600">
                            can not set 0 for {fieldValues?.type}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="mt-3">
                    <span className="flex font-medium">
                      Preview:{" "}
                      {isGenerateOpenAIDripPreviewLoading ||
                      isAddDripPreviewLoading ? (
                        <span>
                          <label className="ml-2">Generating Preview</label>
                          <span className="mt-0.5">
                            <PulseLoader size={3} color="#000000" />
                          </span>
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                    {!isGenerateOpenAIDripPreviewLoading &&
                    !isAddDripPreviewLoading ? (
                      <div>
                        {node?.type === "message" ? (
                          <TextArea
                            name="message"
                            formik={formik}
                            className="border-1 border-gray-300 bg-gray-100 placeholder-gray-700 "
                            rows={6}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            autoComplete="message"
                            placeholder="Preview Message"
                            isNotRemoveExtraSpace={false}
                          />
                        ) : (
                          <div>
                            {node?.type === "mail" && (
                              <div className="my-3">
                                <label className="text-sm font-medium text-semibold mb-3">
                                  Subject:{" "}
                                </label>
                                <div className="text-sm">
                                  <input
                                    type="text"
                                    id="subject"
                                    name="subject"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-[#475361] focus:border-[#475361] sm:text-sm"
                                    onChange={formik.handleChange}
                                    onBlur={(e) => {
                                      const values = e.target.value;
                                      const removeSpace = values
                                        .replace(/\s+/g, " ")
                                        .trim();
                                      formik.handleChange(e);
                                      formik.setFieldValue(
                                        "subject",
                                        removeSpace
                                      );
                                    }}
                                    value={formik?.values?.subject}
                                    placeholder="Subject Template"
                                  />
                                </div>
                              </div>
                            )}
                            <div>
                              {node?.type === "mail" && (
                                <label className="text-sm font-medium text-semibold">
                                  Body:
                                </label>
                              )}

                              <div className="mb-4">
                                <CkEditor
                                  formik={formik}
                                  name={"message"}
                                  editorRef={quillRef}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div></div>
                  </div>
                  <div className="space-y-2 sm:space-y-0 sm:flex justify-end pt-6">
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded-md btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Skip Nodes"}
                      action={() => handleModalBack()}
                      type={"submit"}
                      isDisable={!isValidObject(selectedDrip)}
                      toolTip="Skip Nodes"
                    />
                    {node?.data?.isOpenAI ? (
                      <SpinnerButton
                        className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                        title={"Regenerate Preview"}
                        action={() => generateOpenAIDripResponse()}
                        type={"button"}
                        loading={false}
                        isDisable={isRegenerateButtonDisable()}
                      />
                    ) : (
                      <></>
                    )}
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"See Drip Campaign"}
                      action={() =>
                        window.open(`/drip-flow/${selectedDrip?.id}`, "_blank")
                      }
                      type={"button"}
                      loading={false}
                      isDisable={
                        isAddDripPreviewLoading ||
                        isGenerateOpenAIDripPreviewLoading
                      }
                    />
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Assign To Drip"}
                      action={() => handleAssignDrip()}
                      type={"button"}
                      loading={isAiSuggestedDripAssign}
                      isDisable={isAssignDripDisable()}
                    />
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
