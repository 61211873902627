import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { CalendarIcon } from "@mui/x-date-pickers";
import DateDropDownPicker from "Components/Common/Fields/DateDropDownPicker";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import { dropDownMonths } from "Modules/data";
import { isValidArray } from "Modules/util";
import { Fragment, useEffect } from "react";

export default function AddBirthdayModal(props) {
  const {
    isOpen,
    setIsOpen,
    selectedMonth,
    setSelectedMonth,
    selectedDay,
    setSelectedDay,
    selectedYear,
    setSelectedYear,
    daysOptions,
    setDaysOptions,
    handleLeadBirthday,
    isLoading,
    isEdit,
    validationErros,
    lead,
  } = props;
  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.

  useEffect(() => {
    if (isEdit) {
      if (isValidArray(lead?.leadResponse)) {
        let month = dropDownMonths?.find((month) => {
          return month?.label === lead?.leadResponse?.[0]?.birthMonth;
        });

        setSelectedDay({
          label: lead?.leadResponse?.[0]?.birthDate,
          value: Number(lead?.leadResponse?.[0]?.birthDate),
        });
        setSelectedMonth({
          label: month?.label,
          value: month?.value,
        });
        setSelectedYear({
          label: lead?.leadResponse?.[0]?.birthYear,
          value: Number(lead?.leadResponse?.[0]?.birthYear),
        });
      }
    }
  }, [isEdit, lead?.leadResponse]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[1111]" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl border-b-2 py-2 transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-5">
                  <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        <CalendarIcon className="w-5 text-orange-500 mr-2" />
                        Lead Birthday
                      </Dialog.Title>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 "
                      onClick={() => {
                        setIsOpen(false);
                      }}
                      title="close"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="overflow-visible p-5 flex items-center">
                    <DateDropDownPicker
                      selectedMonth={selectedMonth}
                      setSelectedMonth={setSelectedMonth}
                      selectedDay={selectedDay}
                      setSelectedDay={setSelectedDay}
                      selectedYear={selectedYear}
                      setSelectedYear={setSelectedYear}
                      daysOptions={daysOptions}
                      setDaysOptions={setDaysOptions}
                      validationErros={validationErros}
                    />
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse space-y-2 sm:space-y-0">
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Submit"}
                      action={() => handleLeadBirthday(false)}
                      type={"submit"}
                      loading={isLoading}
                    />
                    <button
                      type="button"
                      className=" w-full justify-center rounded bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
