import moveIcon from "Assets/icons/moveIcon.svg";
import notFound from "Assets/icons/notFound.svg";
import Skeleton from "Components/Common/Fields/Skeleton";
import { isValidArray } from "Modules/util";
import { useGetPropertyVisitsByLeadIdQuery } from "Redux/Leads/PropertySearch/propertySearch";
import { useEffect, useState } from "react";
import PropertyCard from "./PropertyCard";

export default function PropertySearches(props) {
  const { drag, canDrop, lead } = props;
  const [propertyList, setPropertyList] = useState([]);

  // lead wise property search list api
  const {
    data: getPropertySearchByLeadId,
    isLoading: isPropertySearchByLeadId,
  } = useGetPropertyVisitsByLeadIdQuery(
    {
      leadId: lead?.leadResponse?.[0]?._id,
      page: -1,
    },
    { skip: lead?.leadResponse?.[0]?._id ? false : true }
  );

  // effect to save property data in propertyList state
  useEffect(() => {
    if (!isPropertySearchByLeadId) {
      const properties =
        getPropertySearchByLeadId?.data?.getPropertyVisitsByLeadId?.properties;
      if (isValidArray(properties)) {
        setPropertyList(properties);
      } else {
        setPropertyList([]);
      }
    }
  }, [getPropertySearchByLeadId]);

  return (
    <div className="w-full sm:!min-w-[475px] rounded-2xl shadow-md ">
      <div
        className={`leadDeatils-card-header !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
          canDrop && "!bg-orange-300 opacity-85"
        }`}
      >
        <div className="space-x-2 flex items-center ">
          <div className="flex">
            <div className="bg-white w-0.5"></div>
            <div className="text-white pl-[7px] font-medium">
              Property Searches
            </div>
          </div>
        </div>
        <div
          className="bg-orange-600 rounded-md flex items-center p-1.5 h-7 w-7 cursor-move"
          ref={drag}
        >
          <img
            alt="edit-icon"
            src={moveIcon}
            className="h-4 w-4 rounded-md filter invert brightness-0"
            title={"Move Segment"}
          />
        </div>
      </div>
      {/* Card Body */}
      <div
        className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto py-2 px-1 ${
          canDrop && "!bg-orange-300 opacity-85"
        }`}
      >
        {isPropertySearchByLeadId ? (
          <Skeleton counter={3} height={"60px"} className="my-3" />
        ) : isValidArray(propertyList) ? (
          propertyList?.map((property) => <PropertyCard data={property} />)
        ) : (
          <>
            {/* No Property Found  */}
            <div className="flex justify-center pt-[100px]">
              <img
                alt="edit-icon"
                src={notFound}
                className="w-72 grayscale "
                title={"Move Segment"}
              />
            </div>
            <span className="flex justify-center">
              <label className="font-semibold text-gray text-lg">
                No Property Found 😔
              </label>
            </span>
          </>
        )}
      </div>
    </div>
  );
}
