export const gqlBody = {
  AddEmailTemplate(payload) {
    return {
      query: `mutation AddEmailTemplate($input: AddEmailTemplateInput!) {
        addEmailTemplate(input: $input) {
          data {
            _id
            title
            subject
            body
            isDeleted
            createdAt
            updatedAt
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getEmailTemplates(payload) {
    return {
      query: `query EmailTemplate($input: GetAllEmailTemplateInput!) {
        emailTemplates(input: $input) {
          templates {
            _id
            title
            subject
            body
            isDeleted
            createdAt
            updatedAt
          }
          totalCount
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  getEmailTemplate(payload) {
    return {
      query: `query EmailTemplate($input: GetEmailTemplateDetailInput!) {
        emailTemplate(input: $input) {
          data {
            _id
            title
            subject
            body
            isDeleted
            createdAt
            updatedAt
          }
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  UpdateEmailTemplate(payload) {
    return {
      query: `mutation UpdateEmailTemplate($input: UpdateEmailTemplateInput!) {
        updateEmailTemplate(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  DeleteEmailTemplate(payload) {
    return {
      query: `mutation DeleteEmailTemplate($input: DeleteEmailTemplate!) {
        deleteEmailTemplate(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  LeadEmailPreviewInput(payload) {
    return {
      query: `mutation LeadEmailPreview($input: LeadEmailPreviewInput) {
  leadEmailPreview(input: $input) {
    body
  }
}`,
      variables: {
        input: payload,
      },
    };
  },
};
