import trashIcon from "Assets/icons/trashIcon.svg";
import verifyIcon from "Assets/icons/verifyIcon.svg";
import emailVerifiedIcon from "Assets/icons/emailVerifiedIcon.svg";
import emailNotVerified from "Assets/icons/emailNotVerified.svg";

import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import {
  isPermission,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import {
  useEmailStatusUpdateMutation,
  useVerifyLeadEmailMutation,
} from "Redux/Leads/lead";
import { useEffect, useRef, useState } from "react";
import { anyValueIsTrueInObject, EmailOptOutOptions } from "../assetData";
import { OptOutDropDown } from "./OptOutDropDown";
import { useSelector } from "react-redux";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import EditPhoneEmailInLeadProfile from "Components/Common/Fields/EditPhoneEmailInLeadProfile";
import EditMultiInputField from "Components/Common/Fields/EditMultiInputField";
import { ScaleLoader } from "react-spinners";
import useDoubleTap from "Components/Common/Hooks/useDoubleTap";
export default function Email(props) {
  const {
    emailList,
    handleSubmit,
    isSubmit,
    name,
    leadId,
    scrollBarTheme = "orange",
    format,
    isModal,
    setEditFiled = () => {},
    editFiled,
    setIsOpenNestModal = () => {},
    setIsOpenDoubleNestModal = () => {},
    isFetching = "",
  } = props;

  const [limit, setLimit] = useState(true);
  const [List, setList] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [isRemoveValueModalOpen, setRemoveValueModalOpen] = useState(false);
  const [updateEmailStatus] = useEmailStatusUpdateMutation();
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const userPermissions = useSelector((state) => state?.user?.permission); // Get user permissions from Redux store
  const [payload, setPayload] = useState({});
  const [verifyLeadEmail, { isLoading: isVerifyEmailLoading }] =
    useVerifyLeadEmailMutation();
  const [isEmailDoubleTappedData, setIsEmailDoubleTappedData] = useState({});
  const [emailVerifyingIndex, setEmailVerifyingIndex] = useState(""); //state to store the index of verifying email

  function generateEmailList(emailList) {
    const emailInfoList = emailList?.map((obj) => {
      const statusObj = EmailOptOutOptions?.find(
        (opt) => opt?.value === obj?.emailStatus
      );
      if (statusObj) {
        return {
          ...obj,
          icon: statusObj?.icon,
          iconColor: statusObj?.iconColor,
        };
      }
      return obj;
    });

    return emailInfoList;
  }

  useEffect(() => {
    const emailInfoList = generateEmailList(emailList);

    if (limit && emailInfoList?.length > 3) {
      setList(emailInfoList?.slice(0, 3));
    } else {
      setList(emailInfoList);
    }
  }, [limit, emailList]);

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      changeEmailStatusModal: isConfirmationModalOpen,
      deleteEmailModal: isRemoveValueModalOpen,
    }));
  }, [isConfirmationModalOpen, isRemoveValueModalOpen]);

  useEffect(() => {
    if (isConfirmationModalOpen || isRemoveValueModalOpen) {
      setIsOpenDoubleNestModal(true);
    } else {
      setIsOpenDoubleNestModal(false);
    }
  }, [isConfirmationModalOpen, isRemoveValueModalOpen]);
  const removeValue = () => {
    const newList = [...emailList];
    newList?.splice(deleteIndex, 1);
    setList(newList);
    const emailInfoList = generateEmailList(newList);
    setList(emailInfoList);
    let emails = emailInfoList?.map((emailInfo) => emailInfo?.email);
    if (isSubmit) {
      handleSubmit({
        [name]: emails,
      });
    }
    setDeleteIndex(null);
  };

  function handleStatusChange(values) {
    let payload = {
      emailId: values?.id,
      emailStatus: values?.value,
      leadId: leadId,
    };
    updateEmailStatus(payload)
      .then((response) => {
        if (response?.data?.data?.emailStatusUpdate?.type === "success") {
          showSuccessMsg(response?.data?.data?.emailStatusUpdate?.message);
        } else if (response?.data?.data?.emailStatusUpdate?.type === "error") {
          showErrorMsg(response?.data?.data?.emailStatusUpdate?.message);
        }
      })
      .catch((error) =>
        showErrorMsg("Error occurred while updating email status.")
      );
    setConfirmationModalOpen(false);
  }

  function handleConfirmationModal(values) {
    if (values?.value === "Opted-Out") {
      setConfirmationModalOpen(true);
    } else {
      handleStatusChange(values);
    }
  }

  const inputRef = useRef(null);

  const [editingEmailId, setEditingEmailId] = useState(null); // Track the email being edited on double-click
  const [currentEmail, setCurrentEmail] = useState(""); // Track the current email being edited
  const [inputValue, setInputValue] = useState(""); // Track the input value

  // Handle double-click to enter edit mode
  const handleDoubleClick = (index, email) => {
    setEditingEmailId(index);
    setCurrentEmail(email);
    setInputValue(email);
  };

  // Handle input change
  const handleChange = (e) => {
    setCurrentEmail(e.target.value);
  };

  // Save the changes after editing
  const handleSave = () => {
    let updatedEmail = [];
    let trimmedValue = currentEmail?.trim();
    if (name === "email") {
      trimmedValue = trimmedValue?.replace(/\+\d+(?=.*\()|\s|[()]/g, "");
    }
    isValidFormat(trimmedValue) &&
      (updatedEmail = emailList?.map((email) =>
        email?.email === inputValue ? trimmedValue : email?.email
      ));
    const isEmailExists = emailList
      ?.map((e) => e?.email)
      ?.includes(trimmedValue);

    if (isEmailExists) {
      setCurrentEmail("");
      setEditingEmailId(null);
      return;
    }
    if (isSubmit && updatedEmail?.length > 0) {
      handleSubmit({
        [name]: updatedEmail,
      });
      setCurrentEmail("");
      setEditingEmailId(null);
    } else {
      if (!trimmedValue) {
        setCurrentEmail("");
        setEditingEmailId(null);
      } else {
        showErrorMsg("Please enter a valid email");
      }
    }
  };
  // Function to check if the input value is valid
  function isValidFormat(value) {
    return format?.test(value);
  }

  // // Cancel editing
  // const handleCancel = () => {
  //   setCurrentEmail(currentEmail || "");
  //   setEditingEmailId(null);
  // };

  // Handle click outside to close input box
  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      handleSave();
    }
  };

  // Handle Enter key press to save
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const handleVerifyEmail = (index) => {
    setEmailVerifyingIndex(index);
    const emailToVerify = emailList?.[index]?.email;
    verifyLeadEmail({
      email: emailToVerify,
      leadId: leadId,
    })
      .then((response) => {
        if (response?.data?.data?.verifyEmail?.type === "success") {
          showSuccessMsg(response?.data?.data?.verifyEmail?.message);
        } else if (response?.data?.data?.verifyEmail?.type === "error") {
          showErrorMsg(response?.data?.data?.verifyEmail?.message);
        }
      })
      .catch(() => console.log("Error verifying lead Email"));
  };

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentEmail]);

  useEffect(() => {
    setEditFiled((prev) => ({
      ...prev,
      editEmail: editingEmailId === 0 ? true : !!editingEmailId,
    }));
  }, [editingEmailId]);

  const handleDoubleTap = () => {
    anyValueIsTrueInObject(editFiled) &&
      handleDoubleClick(
        isEmailDoubleTappedData?.index,
        isEmailDoubleTappedData?.email
      );
  };
  const handleTouch = useDoubleTap(handleDoubleTap);
  return (
    <div
      className={`xs5:w-full xs:w-[90%] w-full ${!isValidArray(List) && "flex items-center"}
        ${!isModal && "max-w-[260px]"}
       flex-wrap`}
    >
      <ConfirmationModal
        isModalOpen={isRemoveValueModalOpen}
        setModalOpen={setRemoveValueModalOpen}
        handleAction={() => removeValue()}
        isLoading={false}
      />
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setModalOpen={setConfirmationModalOpen}
        message='     
        By changing the email status to "Cannot Be Email" you will no longer be able to send email to this email address.'
        handleAction={() => handleStatusChange(payload)}
        isLoading={false}
      />
      {isModal ? (
        <EditMultiInputField {...props} />
      ) : (
        <EditPhoneEmailInLeadProfile {...props} setLimit={setLimit} />
      )}
      <div
        className={`lg2:w-[250px] px-1 rounded-md ring-gray-300 focus:border-gray-800 focus:ring-gray-500 placeholder:pl-2 placeholder:text-gray-400 text-xs !h-30 ${
          !limit && "max-h-[100px] overflow-y-auto overflow-x-hidden"
        }
        ${isModal && "lg2:w-[275px] "}
        ${scrollBarTheme === "blue" && "scrollbar-blue"}
        `}
      >
        {isValidArray(List) ? (
          List?.map((emailInfo, index) => (
            <div
              className="w-full flex items-center space-x-2 mt-2 h-5 pr-2"
              key={index}
            >
              {editingEmailId === index && window.innerWidth < 460 ? (
                <></>
              ) : (
                <OptOutDropDown
                  Icon={emailInfo?.icon}
                  iconColor={emailInfo?.iconColor}
                  Options={EmailOptOutOptions}
                  setPayload={setPayload}
                  action={handleConfirmationModal}
                  id={emailInfo?._id}
                  status={emailInfo?.emailStatus}
                  disableStatus="Opted-Out"
                  disabled={!isPermission(userPermissions, "edit_leads")}
                />
              )}

              {/* email with onDoubleClick enable editing */}
              {isPermission(userPermissions, "edit_leads") && (
                <>
                  {editingEmailId === index ? (
                    <div className="relative" ref={inputRef}>
                      <input
                        name="email"
                        type="email"
                        value={currentEmail}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        className={`h-6 w-full px-0.5 pr-14 outline-none rounded border border-gray-300 shadow-sm py-2 bg-white ring-gray-300 focus:border-gray-800 focus:ring-gray-500 placeholder:pl-2 placeholder:text-gray-400 sm:text-sm sm:leading-6 ${
                          editingEmailId === index ? "bg-white" : "bg-gray-100"
                        } 
                    `}
                      ></input>
                      {editingEmailId === index && (
                        <>
                          <button
                            onClick={() => {
                              setEditingEmailId(null);
                            }}
                            className="absolute right-[26px] w-[18px] h-[18px] top-3 transform -translate-y-1/2 bg-red-100 rounded-md border border-red-400 text-red-700 hover:bg-red-200 hover:text-red-900"
                          >
                            <XMarkIcon className="w-[16px] h-[16px] font-semibold" />
                          </button>
                          <button
                            onClick={() => handleSave(index)}
                            className="absolute right-1 w-[18px] h-[18px] top-3 transform -translate-y-1/2 bg-green-100 rounded-md border border-green-400 text-green-700 hover:bg-green-200 hover:text-green-900"
                          >
                            <CheckIcon className="w-[15px] h-[15px] font-semibold" />
                          </button>
                        </>
                      )}
                    </div>
                  ) : (
                    <span
                      title={emailInfo?.email}
                      className={`cursor-pointer w-[180px] !truncate ${
                        emailInfo?.emailStatus === "Opted-Out" &&
                        "w-[170px] pl-3.5 "
                      }
                      ${!isModal && "!w-[160px]"}
                      `}
                      onDoubleClick={() =>
                        anyValueIsTrueInObject(editFiled) &&
                        handleDoubleClick(index, emailInfo?.email)
                      }
                      onTouchStart={() => {
                        handleTouch();
                        setIsEmailDoubleTappedData({
                          index,
                          email: emailInfo?.email,
                        });
                      }} // Handle touch for double tap
                    >
                      {emailInfo?.email}
                    </span>
                  )}
                </>
              )}
              {editingEmailId !== index && (
                <>
                  {isPermission(userPermissions, "edit_leads") && (
                    <div className="bg-white rounded-md ml-3 cursor-pointer flex align-center items-center">
                      <img
                        alt="edit-icon"
                        src={trashIcon}
                        className={`flex items-center rounded w-4 h-4 mr-1 ${
                          emailInfo?.emailStatus === "Opted-Out" && "ml-3.5"
                        }`}
                        onClick={() => {
                          setDeleteIndex(index);
                          setRemoveValueModalOpen(true);
                        }}
                        title={"Remove email"}
                      />

                      {!!emailList?.[index]?.isVerified ? (
                        <img
                          src={emailVerifiedIcon}
                          alt="verified-icon"
                          className="w-8 h-5 cursor-default"
                          title={"Email verified"}
                        />
                      ) : emailList?.[index]?.isVerified === null ? (
                        (!isVerifyEmailLoading && !isFetching) ||
                        emailVerifyingIndex !== index ? (
                          <img
                            src={verifyIcon}
                            alt="verify-icon"
                            title={"Verify Email"}
                            className={`w-8 mt-0 font-bold`}
                            onClick={() => handleVerifyEmail(index)}
                          />
                        ) : (
                          <ScaleLoader
                            color="orange"
                            size={3}
                            width={4}
                            height={16}
                          />
                        )
                      ) : (
                        <img
                          src={emailNotVerified}
                          alt="not-verified-icon"
                          title={"Email Unverified"}
                          className="w-8 h-5 cursor-default"
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          ))
        ) : (
          <div className="flex justify-center w-full mt-3">
            Lead doesn't have any email
          </div>
        )}
      </div>
      <div className="px-3 mt-1">
        {emailList?.length > 3 && (
          <span
            className="text-blue-500 text-xs hover:cursor-pointer hover:underline"
            onClick={() => setLimit(!limit)}
          >
            {limit ? "Show More" : "Show Less"}
          </span>
        )}
      </div>
    </div>
  );
}
