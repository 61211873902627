import { EyeIcon, HomeIcon } from "@heroicons/react/24/solid";
import { showLeadDateFormat } from "Modules/util";

export default function PropertyCard(props) {
  const { data } = props;
  return (
    <div className="flex text-xs text-gray-700 space-x-1.5 rounded-lg p-2 m-2 shadow-md border bg-gray-100">
      <div>
        <img src={data?.image} className="w-24" alt="Property" />
      </div>
      <div className="w-full flex justify-between">
        <div>
          <span
            dangerouslySetInnerHTML={{ __html: data?.mapRedirect }}
            className="font-semibold text-blue-500 hover:underline"
          />
          <div className="flex space-x-1">
            <label className="block mt-2">
              <b>{data?.bathroom}</b> Baths
            </label>
            <label className="block mt-2">|</label>
            <label className="block mt-2">
              <b>{data?.bedroom}</b> Beds
            </label>
          </div>
        </div>
        <span className="font-semibold text-green-500 ">{data?.price}</span>
        <div>
          <div className="flex space-x-1">
            <EyeIcon className="w-4 h-4 text-orange-500" />
            <label className="font-semibold">
              {data?.date ? `on ${showLeadDateFormat(data?.date)}` : "-"}
            </label>
          </div>
          <span className="flex space-x-1">
            <HomeIcon className="w-4 h-4 text-orange-500" />
            <span className="font-semibold">{data?.listingType}</span>
          </span>
        </div>
      </div>
    </div>
  );
}
