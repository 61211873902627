import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useEffect, useState } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import { addDays } from "date-fns";
import moment from "moment/moment";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";

export default function DateRangePickerModal({
  handleSelectDate,
  defaultStartDate,
  defaultEndDate,
  buttonLable,
  isDisabled = false,
  title = "",
  setIsOpenNestModal = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);
  useEscapeClose(setIsOpen); // Custom hook to close a modal when the Escape key is pressed.
  const [state, setState] = useState([
    {
      startDate: defaultStartDate || new Date(),
      endDate: defaultEndDate || addDays(new Date(), 0),
      key: "selection",
    },
  ]);
  const convertTimeIntoStartDay = (time) => {
    return moment(time).startOf("day").toDate();
  };
  const convertTimeIntoEndDay = (time) => {
    return moment(time).endOf("day").toDate();
  };

  useEffect(() => {
    if (isOpen) {
      setIsOpenNestModal(true);
    } else {
      setIsOpenNestModal(false);
    }
  }, [isOpen]);

  return (
    <>
      <button
        className="text-sm border border-gray-400 hover:border-gray-800 bg-white text-gray-800 hover:text-gray-900 w-full py-1 px-4 rounded-md transition duration-300 ease-in-out disabled:opacity-65 disabled:cursor-not-allowed"
        type="button"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
        disabled={isDisabled}
        title={isDisabled ? title : ""}
      >
        {buttonLable}
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1111]"
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-min border-b-2 py-1 pr-3 transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <div className="mt-1 border-b-2 py-2 sm:mt-0 flex justify-between items-center">
                    <div className="sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg pl-5 font-medium leading-6 text-gray-800 flex justify-between items-center"
                      >
                        <CalendarDaysIcon className="w-6 text-orange-500 mr-2" />
                        Select Date Range
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="flex flex-col m-3">
                    <DateRangePicker
                      onChange={(item) => setState([item?.selection])}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={2}
                      ranges={state}
                      rangeColors={["#fb923c"]}
                      direction="horizontal"
                      className="custom-date-range-picker  w-[90vw] lg:w-auto overflow-x-auto" // Add a custom className name
                    />
                    <div className="flex flex-col sm:flex-row justify-end mt-4">
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded bg-gray-50 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={(e) => {
                          e.preventDefault();
                          setState([
                            {
                              startDate: null,
                              endDate: null,
                              key: "selection",
                            },
                          ]);
                          handleSelectDate(null, null);
                          setIsOpen(false);
                        }}
                      >
                        Clear
                      </button>
                      <SpinnerButton
                        className="inline-flex w-full justify-center !bg-gray-500 rounded btn-orange px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto mt-3 sm:mt-0"
                        title="Cancel"
                        action={() => {
                          setIsOpen(false);
                        }}
                        loading={false}
                      />
                      <SpinnerButton
                        className="inline-flex w-full justify-center rounded btn-orange px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto mt-3 sm:mt-0"
                        title="Select"
                        action={() => {
                          setIsOpen(false);
                          if (state[0]?.startDate && state[0]?.endDate) {
                            handleSelectDate(
                              convertTimeIntoStartDay(state[0]?.startDate),
                              convertTimeIntoEndDay(state[0]?.endDate)
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
