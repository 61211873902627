export const gqlBody = {
  getEmailHistoryByLeadId(payload) {
    return {
      query: `query EmailResponse($input: inputSingleLead) {
  getEmailHistoryByLeadId(input: $input) {
    emailResponse {
      _id
      from
      to
      subject
      date
      body
      isOpened
      openedAt
      openedCount
      type
      status
      reason
    }
    code
    message
    type
  }
}`,
      variables: { input: payload },
    };
  },
};
