import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid";
import { zilliowIcon } from "Assets/icons/SvgIcons";
import {
  getLocalStorage,
  isPermission,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { useMakeCallMutation } from "Redux/Leads/lead";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClosedLeadInfoModal from "../Modal/CloseLeadInfoModal";
import MakeCallModal from "../Modal/MakeCallModal";
import SendMailModal from "../Modal/SendMailModal";

const ContactLead = ({ lead, leadData, setIsOpenNestModal = () => {} }) => {
  const isPhoneNumber = getLocalStorage("isPhoneNumber");
  const userPermissions = useSelector((state) => state?.user?.permission);
  const [leadId, setLeadId] = useState("");
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [emailOpations, setEmailOpations] = useState([]);
  const [makeCall, { isLoading: isMakeCallLoading }] = useMakeCallMutation();
  const [isOpenCall, setIsOpenCall] = useState(false);
  const [phoneList, setPhoneList] = useState([]);
  const [isClosedLeadModal, setIsClosedLeadModal] = useState(false);

  useEffect(() => {
    if (isValidArray(lead?.leadResponse)) {
      // Setting up options for email and SMS
      setLeadId(lead?.leadResponse[0]?._id);
      setEmailOpations([
        {
          _id: lead?.leadResponse[0]?._id,
          label: `(${lead?.leadResponse?.[0]?.firstName}) -${lead?.leadResponse[0]?.email?.[0]}`,
          value: lead?.leadResponse[0]?.email,
        },
      ]);

      let leadContact = lead?.leadResponse[0]?.contact;

      if (isValidArray(leadContact)) {
        leadContact = leadContact
          ?.filter(
            (phoneInfo) =>
              phoneInfo?.callStatus !== "Do Not Call" &&
              phoneInfo?.callStatus !== "Wrong Number"
          )
          .map((phoneInfo) => phoneInfo?.phone);

        setPhoneList(leadContact);
      }
    }
  }, [lead?.leadResponse, isOpenEmail, isOpenCall]);
  //
  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      sendMailModal: isOpenEmail,
      makeCallModal: isOpenCall,
    }));
  }, [isOpenEmail, isOpenCall]);

  function handleMakeCall(number) {
    let obj = {
      leadId: leadId,
      leadPhoneNo: number,
    };
    makeCall(obj)
      .then((response) => {
        const makeCallResponse = response?.data?.data?.makeCall;
        if (makeCallResponse?.type === "success") {
          showSuccessMsg(makeCallResponse?.message);
        } else if (makeCallResponse?.type === "error") {
          showErrorMsg(makeCallResponse?.message);
        }
      })
      .catch((error) => {
        showErrorMsg("Error occurred while Calling.");
      });
    setIsOpenCall(false);
  }

  const checkMakeCallDisabled = () => {
    // Check if the phone number status is true
    if (isPhoneNumber !== "true") {
      return { disabled: true, title: "Please buy a number." };
    }
    // Check if the lead contact array is invalid or empty
    if (!isValidArray(lead?.leadResponse?.[0]?.contact)) {
      return { disabled: true, title: "Please enter the lead phone number." };
    }
    // Default case where the phone number is valid
    return { disabled: false, title: "Make call" };
  };

  function handleOpenZillowProfile() {
    if (!lead?.leadResponse?.[0]?.zillowURL) {
      return;
    }
    window.open(lead?.leadResponse?.[0]?.zillowURL, "_blank");
  }

  return (
    <>
      {isOpenEmail && (
        <SendMailModal
          isOpen={isOpenEmail}
          setIsOpen={setIsOpenEmail}
          options={emailOpations}
          leadId={leadId}
          setLeadId={setLeadId}
          lead={lead}
        />
      )}
      <MakeCallModal
        setModalOpen={setIsOpenCall}
        isModalOpen={isOpenCall}
        handleAction={handleMakeCall}
        isLoading={isMakeCallLoading}
        phoneList={phoneList}
      />
      <ClosedLeadInfoModal
        leadId={leadId}
        leadData={leadData}
        setModalOpen={setIsClosedLeadModal}
        isModalOpen={isClosedLeadModal}
        LeadIsClosed={
          isValidArray(lead?.leadResponse)
            ? lead?.leadResponse[0]?.isClosed
            : false
        }
      />

      <div className="flex w-full justify-center h-full mt-9 space-x-3 text-sm disabled:opacity-75 xxs:flex-nowrap">
        {isPermission(userPermissions, "send_email") ? (
          <button
            className="flex text-xs items-center font-medium bg-white text-balck py-2 px-4  rounded shadow-md border border-sm disabled:opacity-75 disabled:cursor-not-allowed"
            onClick={() =>
              isValidArray(lead?.leadResponse?.[0]?.email)
                ? setIsOpenEmail(true)
                : showErrorMsg("Please enter the lead email address.")
            }
            disabled={
              !isValidArray(lead?.leadResponse?.[0]?.email) ? true : false
            }
            title={
              !isValidArray(lead?.leadResponse?.[0]?.email)
                ? "Please enter the lead email address"
                : "Send Mail"
            }
          >
            <EnvelopeIcon className="w-4 h-4 mr-1 text-blue-500" />
            Send Email
          </button>
        ) : (
          <></>
        )}
        {isPermission(userPermissions, "make_call") ? (
          <button
            className="flex text-xs items-center  font-medium bg-white text-balck py-2 px-4 rounded shadow-md border border-sm disabled:opacity-75 disabled:cursor-not-allowed"
            disabled={checkMakeCallDisabled()?.disabled}
            title={checkMakeCallDisabled()?.title}
            onClick={() =>
              !isValidArray(phoneList)
                ? showErrorMsg("Lead don't have any valid phone number.")
                : isValidArray(lead?.leadResponse?.[0]?.phone)
                ? setIsOpenCall(true)
                : showErrorMsg("Please enter the lead phone number.")
            }
          >
            <PhoneIcon className="w-4 h-4 mr-1 text-blue-600" />
            Make Call
          </button>
        ) : (
          <></>
        )}

        {lead?.leadResponse?.[0]?.zillowURL ? (
          <button
            className="flex space-x-1 items-center text-xs font-medium bg-white text-balck py-2 px-4 rounded shadow-md border border-sm disabled:opacity-75 disabled:cursor-not-allowed hover:cursor-pointer"
            onClick={() => handleOpenZillowProfile()}
            disabled={false}
          >
            {zilliowIcon}{" "}
            <label className="disabled:cursor-not-allowed hover:cursor-pointer">
              View In Zillow
            </label>
          </button>
        ) : (
          <></>
        )}

        {/* {isPermission(userPermissions, "edit_leads") ? (
          <button
            className="flex text-xs font-medium bg-white text-black py-2 px-4 rounded shadow-md border border-sm"
            onClick={() => setIsClosedLeadModal(true)}
            title={
              (isValidArray(lead?.leadResponse) &&
                lead?.leadResponse[0]?.isClosed === "true") ||
              (isValidArray(lead?.leadResponse) &&
                lead?.leadResponse[0]?.isClosed === true)
                ? "Open Lead"
                : "Close Lead"
            }
          >
            {(isValidArray(lead?.leadResponse) &&
              lead?.leadResponse[0]?.isClosed === "true") ||
            (isValidArray(lead?.leadResponse) &&
              lead?.leadResponse[0]?.isClosed === true) ? (
              <PlusCircleIcon className="w-4 h-4 mr-2 text-blue-600" />
            ) : (
              <MinusCircleIcon className="w-4 h-4 mr-2 text-blue-600" />
            )}
            {(isValidArray(lead?.leadResponse) &&
              lead?.leadResponse[0]?.isClosed === "true") ||
            (isValidArray(lead?.leadResponse) &&
              lead?.leadResponse[0]?.isClosed === true)
              ? "Open Lead"
              : "Close Lead"}
          </button>
        ) : (
          <></>
        )} */}
      </div>
    </>
  );
};

export default ContactLead;
