import {
  PhoneDigitFormat,
  emailFormat,
  phoneFormat,
} from "Components/Common/formatter";
import ContactLead from "../ContactLead";
import Email from "../Email";
import Phone from "../Phone";
import CategoryDropdown from "./CategoryDropdown";
import { useState } from "react";
import LeadTypeDropdown from "./LeadTypeDropdown";
import LeadNameEditModal from "./LeadNameEditModal";

export default function LeadProfile(props) {
  const {
    lead,
    formik,
    handleSubmit,
    disabled,
    isFetching,
    setIsEditableOpen,
    isEditableOpen,
    handleModalClick,
    phoneInitialValues,
    emailInitialValues,
    leadId,
    phoneList,
    emailList,
    emailCharFormat,
    allCategory,
    setIsOpenNestModal,
  } = props;

  const leadData = lead?.leadResponse?.[0];
  const leadCategoryData = lead?.categoryResponse?.[0];
  const [editFiled, setEditFiled] = useState({});
  const [isOpenDoubleNestModal, setIsOpenDoubleNestModal] = useState(false);
  const [outSideFocus, setOutSideFocus] = useState(false);

  return (
    <div className="sm:min-w-[475px] w-full shadow-md rounded-2xl">
      <div className="leadDetails-Bluecard-header sm:h-[40px] rounded-t-2xl flex xs2:items-center xs2:justify-between sm:flex-row flex-col px-4">
        <div className="xs2:space-x-2 space-x-3 flex xs2:items-center items-start xs2:flex-nowrap flex-wrap">
          <LeadNameEditModal
            leadFirstName={leadData?.firstName}
            leadLastName={leadData?.lastName}
            handleSubmit={handleSubmit}
            outSideFocus={outSideFocus}
          />
          <div className="xs2:space-x-2 space-x-3 flex xs2:items-center items-start xs2:flex-nowrap flex-wrap">
            <CategoryDropdown
              allCategory={allCategory}
              leadCategoryData={leadCategoryData}
              handleSubmit={handleSubmit}
              setOutSideFocus={setOutSideFocus}
            />
            <LeadTypeDropdown
              handleSubmit={handleSubmit}
              leadTypeInfo={leadData?.leadType}
            />
          </div>
        </div>
        <div className="mr-3">
          <span className="rounded text-xs text-white">Last Active: </span>
          <span className="rounded text-xs text-white font-bold">-</span>
        </div>
      </div>
      <div className="leadDeatils-card-body sm:h-[200px] rounded-b-2xl py-2 px-1 ">
        <div className="flex justify-between items-start sm:flex-row flex-col sm:h-[110px]">
          <div className="flex-1 mx-3">
            <Phone
              id="phone"
              name="phone"
              formik={formik}
              isSubmit={true}
              format={phoneFormat}
              type={PhoneDigitFormat}
              handleSubmit={handleSubmit}
              disabled={disabled}
              isFetching={isFetching}
              setIsEditableOpen={setIsEditableOpen}
              isEditableOpen={isEditableOpen}
              handleModalClick={handleModalClick}
              initialValues={phoneInitialValues}
              phoneList={phoneList}
              showTags={false}
              leadId={leadId}
              addPhoneInput={false}
              scrollBarTheme={"blue"}
              setEditFiled={setEditFiled}
              editFiled={editFiled}
              setIsOpenNestModal={setIsOpenNestModal}
              setIsOpenDoubleNestModal={setIsOpenDoubleNestModal}
            />
          </div>
          <div className="flex-1 mx-3">
            <Email
              id="email"
              name="email"
              format={emailFormat}
              formik={formik}
              isSubmit={true}
              handleSubmit={handleSubmit}
              type={emailCharFormat}
              disabled={disabled}
              isFetching={isFetching}
              setIsEditableOpen={setIsEditableOpen}
              isEditableOpen={isEditableOpen}
              handleModalClick={handleModalClick}
              initialValues={emailInitialValues}
              emailList={emailList}
              showTags={false}
              leadId={leadId}
              addEmailInput={false}
              scrollBarTheme={"blue"}
              setEditFiled={setEditFiled}
              editFiled={editFiled}
              setIsOpenNestModal={setIsOpenNestModal}
              setIsOpenDoubleNestModal={setIsOpenDoubleNestModal}
            />
          </div>
        </div>

        <div>
          <ContactLead
            lead={lead}
            leadData={leadData}
            setIsOpenNestModal={setIsOpenNestModal}
          />
        </div>
      </div>
    </div>
  );
}
