import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a TaskQuery object using serverApi.injectEndpoints
const TaskQuery = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    addTask: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.addTask(payload),
      }),
      invalidatesTags: ["HistoryByLeadId", "TaskByLeadId", "Lead"],
    }),
    deleteTask: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.deleteTask(payload),
      }),
      invalidatesTags: ["HistoryByLeadId"],
    }),
    getTask: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.getTask(payload),
      }),
    }),
    editTask: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.editTask(payload),
      }),
      invalidatesTags: ["TaskList", "AgentList", "HistoryByLeadId", "Lead"],
    }),
    CompleteTask: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.completeTask(payload),
      }),
      invalidatesTags: ["HistoryByLeadId", "LeadList"],
    }),
    listTodo: builder.query({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.listTodo(payload),
      }),
      providesTags: ["todoList"],
    }),
    addTodo: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.addTodo(payload),
      }),
      invalidatesTags: ["todoList", "Lead"],
    }),
    sortTask: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.sortTask(payload),
      }),
    }),
    taskOrder: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.taskOrder(payload),
      }),
      invalidatesTags: ["TaskList"],
    }),
    getSingleTask: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getTask(payload),
        };
      },
      providesTags: ["Task"],
    }), listTodayTask: builder.query({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.listTodayTasks(payload),
      }),
      providesTags: ["TaskList"],
    }),

    listCompletedTask: builder.mutation({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.listCompletedTasks(payload),
      }),
      providesTags: ["TaskList"],
    }),

    listUpcomingTask: builder.query({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.listUpcomingTasks(payload),
      }),
      providesTags: ["TaskList"],
    }),

    listPastDueTask: builder.query({
      query: (payload) => ({
        method: "POST",
        body: gqlBody.listPastDueTasks(payload),
      }),
      providesTags: ["TaskList"],
    }),

  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useAddTaskMutation,
  // useListTaskQuery,
  useDeleteTaskMutation,
  useGetTaskMutation,
  useEditTaskMutation,
  useCompleteTaskMutation,
  useListTodoQuery,
  useAddTodoMutation,
  useSortTaskMutation,
  useTaskOrderMutation,
  useGetSingleTaskQuery,
  useListTodayTaskQuery,
  useListCompletedTaskMutation,
  useListUpcomingTaskQuery,
  useListPastDueTaskQuery,

} = TaskQuery;
