export const gqlBody = {
  GetPropertyVisitsByLeadId(payload) {
    return {
      query: `query GetPropertyVisitsByLeadId($input: GetPropertyVisitsInput) {
          getPropertyVisitsByLeadId(input: $input) {
            type
            message
            code
            properties {
              listingType
              price
              address
              mapRedirect
              bedroom
              bathroom
              image
              leadId
              userId
              date
              isFromZillow
            }
            totalCount
          }
        }`,
      variables: { input: payload },
    };
  },
};
