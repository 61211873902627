import { Tab } from "@headlessui/react";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import refreshIcon from "Assets/icons/refreshIcon.svg";
import SpinnerButton from "Components/Common/Buttons/SpinnerButton";
import {
  isObjHaveValue,
  isPermission,
  isValidArray,
  leadStoryPointsDateFormat,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import { useLeadStoryPromptMutation } from "Redux/Leads/lead";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";

export default function LeadStory(props) {
  const { lead } = props;
  const [leadStoryPrompt, { isLoading }] = useLeadStoryPromptMutation();
  const userPermissions = useSelector((state) => state?.user?.permission); // Get user permissions from Redux store
  const leadData = lead?.leadResponse?.[0];

  function handleStoryAIGenerate(prompt) {
    if (isLoading) {
      return;
    }

    let payload = {
      leadId: leadData?._id,
    };

    leadStoryPrompt(payload)
      .then((response) => {
        if (response?.data?.data?.leadStory?.type === "success") {
          showSuccessMsg(response?.data?.data?.leadStory?.message);
        } else if (response?.data?.data?.leadStory?.type === "error") {
          showErrorMsg(response?.data?.data?.leadStory?.message);
        } else if (response?.error) {
          showErrorMsg("Error occurred while Generating Story");
        }
      })
      .catch((error) => showErrorMsg("Error occurred while Generating Story."));
  }

  return (
    <div className="sm:min-w-[475px] w-full shadow-md rounded-2xl">
      <Tab.Group>
        <div className="leadDetails-Bluecard-header xs2:h-[40px] h-[60px] rounded-t-2xl flex items-center justify-between px-4">
          <div className="space-x-1 flex items-center">
            <div className="bg-white w-0.5 h-[24px] xxs:inline-block hidden"></div>
            <Tab.List className="flex h-auto xs2:flex-nowrap flex-wrap xs2:space-y-0 space-y-1 xs2:mt-0 mt-1">
              <Tab
                className={({ selected }) =>
                  `cursor-pointer px-3 focus:outline-none focus:ring-0 ${
                    selected
                      ? "border-0 rounded-full bg-blue-500 text-white"
                      : "text-slate-200 hover:bg-blue-500 hover:text-white hover:rounded-full"
                  }`
                }
              >
                {leadData?.firstName}'s Story
              </Tab>
              <div className="bg-white w-[1px] h-[18px] mx-2 mt-1 xxs:inline-block hidden"></div>
              {/* <div className="bg-white w-[] h-[1px] mx-2 mt-1 inline-block xxs:hidden"></div> */}
              <Tab
                className={({ selected }) =>
                  `cursor-pointer px-3 focus:outline-none focus:ring-0 ${
                    selected
                      ? "border-0 bg-blue-500 text-white rounded-full"
                      : "text-slate-200 hover:bg-blue-500 hover:text-white hover:rounded-full"
                  }`
                }
              >
                Story Highlights
              </Tab>
            </Tab.List>
          </div>
          {leadData?.story ? (
            <div className="h-7 w-7 flex justify-center items-center bg-blue-600 rounded-md p-1 !shrink-0">
              {isLoading ? (
                <BeatLoader
                  className="flex items-center"
                  color="#ffff"
                  size={3}
                  width={10.5}
                  height={4}
                />
              ) : (
                <img
                  alt="refresh-icon"
                  src={refreshIcon}
                  className="h-4 w-4 hover:cursor-pointer rounded-md filter invert  brightness-0 !shrink-0"
                  onClick={() => handleStoryAIGenerate()}
                  title="Re-generate Story"
                  isDisabled={isLoading}
                />
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="leadDeatils-card-body xs:h-[200px] h-[230px] rounded-b-2xl px-2 pb-2">
          <Tab.Panels>
            <Tab.Panel>
              {leadData?.story ? (
                <>
                  <div
                    className={`xs:h-[185px] h-[215px] overflow-y-auto text-xs text-justify p-2`}
                  >
                    {leadData?.story?.replace(/\*\*.*?\*\*/g, "")}
                  </div>
                </>
              ) : (
                isPermission(userPermissions, "edit_leads") && (
                  <div className="!h-[200px]">
                    <div className="text-sm h-full flex justify-center items-center">
                      <SpinnerButton
                        type="submit"
                        label={`Generate ${leadData?.firstName}'s Story`}
                        title="Generate Story"
                        className="btn-story rounded-lg text-sm font-medium px-2 py-1"
                        isLoading={isLoading ? true : false}
                        isDisable={false}
                        action={() => handleStoryAIGenerate()}
                      />
                    </div>
                  </div>
                )
              )}
            </Tab.Panel>
            <Tab.Panel>
              {isValidArray(leadData?.storyHighlights) ? (
                <ul className="list-disc pl-5 space-y-1 p-2 overflow-y-auto !h-[200px] ">
                  {leadData?.storyHighlights?.map((item, index) =>
                    isObjHaveValue(item) ? (
                      <li
                        key={index}
                        className="text-xs break-words text-justify"
                      >
                        <div className="flex">
                          <span className="flex text-nowrap">
                            {leadStoryPointsDateFormat(item?.date)}
                          </span>
                          <div className="bg-gray-600 !h-[15px] !w-[2px] mx-2" />
                          <p className="w-full">{item?.highlight}</p>
                        </div>
                      </li>
                    ) : (
                      ""
                    )
                  )}
                </ul>
              ) : (
                <div className="!h-[200px] flex items-center justify-center">
                  <p className="text-xs text-gray-600">
                    Lead doesn't have a story
                  </p>
                </div>
              )}
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
}
