import React from "react";
import "./editor.css";
import { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "ckeditor5/ckeditor5.css";
import { variablesArray, withoutUnSubscribeLinkVariablesArray } from "../data";
import { config, handleButtonClick } from "./editorAssets";
import { DecoupledEditor } from "ckeditor5";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import he from "he";

const Editor = ({
  formik,
  name,
  label = "body",
  isVariable = true,
  isUnSubscribe = false,
  preview = "",
  isPreview = false,
  handlePreview = () => {},
}) => {
  const loginUser = useSelector((state) => state?.user?.currentUser); //getting current logged in user
  const editorToolbarRef = useRef(null);
  const editorRef = useRef(null);
  const [isMounted, setMounted] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const decodedData = he.decode(preview || "<p><br/></p>");
  const isDisabled = (item) => {
    if (item === "emailSignature") {
      return loginUser?.emailSignature?.trim() ? false : true;
    }
    return false;
  };
  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
      setIsReadOnly(false);
    };
  }, []);

  const handleKeydown = (event, editor) => {
    const isEnter = event.key === "Enter";
    const isShiftEnter = event.shiftKey && isEnter;

    if (isShiftEnter) {
      // Default 'Shift + Enter' (new paragraph) action: Change to a normal Enter behavior
      event.preventDefault();
      editor.execute("enter");
    } else if (isEnter) {
      // Default 'Enter' (new line) action: Change to Shift + Enter (line break)
      event.preventDefault();
      editor.execute("shiftEnter");
    }
  };
  return (
    <div className="h-full">
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900 mb-1"
      >
        {label}
      </label>
      {isVariable && (
        <div className="flex gap-x-2  flex-wrap">
          {(isUnSubscribe
            ? variablesArray
            : withoutUnSubscribeLinkVariablesArray
          )?.map((item, index) => (
            <div className="relative" key={index + 1}>
              <p
                key={item}
                title={isDisabled(item) ? "Add signature in profile modal" : ""}
                style={{
                  cursor: isDisabled(item) ? "not-allowed" : "pointer",
                  opacity: isDisabled(item) ? "0.65" : "1",
                }}
                className={`p-1 px-6 my-2 rounded-lg font-base text-sm bg-white border  transition duration-500 ease-in-out ${
                  clickedItem === item
                    ? "!bg-orange-100 text-orange-700"
                    : "bg-white text-black"
                }
                  ${isReadOnly ? "opacity-70" : ""}
                  }`}
                onClick={() => {
                  if (!isDisabled(item) && !isReadOnly) {
                    handleButtonClick(item, editorRef);
                    setClickedItem(item); // Set clicked item here
                    setTimeout(() => setClickedItem(null), 600); // Reset after 300ms
                  }
                }}
              >
                {item === "unsubscribeLink" ? `unsubscribeLink` : `{{${item}}}`}
              </p>
            </div>
          ))}
          {isPreview ? (
            <div className="relative ml-auto">
              <button
                className={classNames(
                  "p-1 my-2 rounded-lg font-base border border-gray-300 text-sm shadow-lg",
                  isReadOnly
                    ? "bg-white !text-orange-600"
                    : "bg-white !text-orange-600"
                )}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsReadOnly((prev) => !prev);
                  handlePreview();
                }}
                title={
                  isReadOnly
                    ? "Switch to Email Edit Mode"
                    : "Switch to Email Preview Mode"
                }
              >
                {isReadOnly ? (
                  <EyeIcon className="h-5 w-5 shrink-0 cursor-pointer" />
                ) : (
                  <EyeSlashIcon className="h-5 w-5 shrink-0 cursor-pointer" />
                )}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      <div
        style={{
          visibility: isReadOnly ? "hidden" : "visible",
          height: isReadOnly ? "0px" : "auto",
        }}
        ref={editorToolbarRef}
      ></div>
      <div
        style={{ height: isReadOnly ? "350px" : "0px" }}
        className=" overflow-y-auto border border-gray-300 rounded-lg rounded-t-none"
      >
        <CKEditor
          config={config}
          data={decodedData}
          editor={DecoupledEditor}
          disabled={true}
        />
      </div>
      <div
        style={{ height: !isReadOnly ? "350px" : "0px" }}
        className="overflow-y-auto border border-gray-300 rounded-lg rounded-t-none"
      >
        {isMounted && (
          <CKEditor
            editor={DecoupledEditor}
            data={formik?.values?.[name] || "<p><br></p>"}
            config={config}
            onFocus={(event, editor) => editor.editing.view.focus()}
            onBlur={(event, editor) => {
              formik.setFieldTouched(name, true);
            }}
            onReady={(editor) => {
              editorRef.current = { editor };
              if (editorToolbarRef.current) {
                editorToolbarRef.current.appendChild(
                  editor.ui.view.toolbar.element
                );
              }
              editor.editing.view.document.on("keydown", (event, data) =>
                handleKeydown(data.domEvent, editor)
              );
            }}
            onAfterDestroy={(editor) => {
              if (editorToolbarRef.current) {
                Array.from(editorToolbarRef.current.children).forEach((child) =>
                  child.remove()
                );
              }
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              formik?.setFieldValue([name], data);
            }}
          />
        )}
      </div>
      {formik.touched?.[name] && formik.errors?.[name] && !isReadOnly && (
        <div className="text-red-500 text-sm mt-[2px]">
          {formik?.errors?.[name]}
        </div>
      )}
    </div>
  );
};

export default Editor;
