import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a Call object using serverApi.injectEndpoints
const Call = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    getCallByLeadId: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getCallByLeadId(payload),
        };
      },
      providesTags: ["CallByLeadId"],
    }),
    addCallLogs: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.addCallLogs(payload),
        };
      },
      invalidatesTags: ["CallByLeadId", "HistoryByLeadId","LeadList"],
    }),
    deleteCall: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.deleteCall(payload),
        };
      },
      invalidatesTags: ["CallByLeadId", "HistoryByLeadId","LeadList"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useGetCallByLeadIdQuery,
  useDeleteCallMutation,
  useAddCallLogsMutation,
} = Call;
