export const gqlBody = {
    emailReport(payload) {
      return {
        query: `query SentEmailList($input: SentEmailListInput) {
            sentEmailList(input: $input) {
              type
              totalCount
              response {
                from
                to
                subject
                status
                openedCount
                openedAt
                body
                isOpened
                createdAt
                type
                reason
                lead {
                  _id
                  firstName
                  lastName
                  email
                  emailStatusDetail {
                    email
                    emailStatus
                    isVerified
                    _id
                  }
                }
              }
              message
              code
            }
          }`,
          variables: {
            input: payload,
          },
      };
    },
  };
  