import Context from "Context";
import { store } from "Redux/store";
import { CookiesProvider } from "react-cookie";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ".";
import RoutesFile from "./RoutesFile";
import { Suspense } from "react";
import Loading from "Components/Common/Loading";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

function App() {
  const isMobile = window.matchMedia("(hover:none)").matches;

  return (
    <Suspense fallback={<Loading />}>
      <CookiesProvider defaultSetOptions={{ path: "/" }}>
        <Provider store={store}>
          <Context />
          <ToastContainer
            // position="bottom-right"
            position={isMobile ? "top-center" : "bottom-right"}
            autoClose={8000}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover={false}
            theme="light"
            className={"z-50"}
            style={{
              zIndex: 9999999999,
              ...(window.innerWidth <= 400
                ? { width: "70%", bottom: "1px", padding: "6px" }
                : {}),
            }}
          />
          <BrowserRouter>
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
              <RoutesFile />
            </DndProvider>
          </BrowserRouter>
        </Provider>
      </CookiesProvider>
    </Suspense>
  );
}

export default App;
