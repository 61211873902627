import { isValidArray } from "Modules/util";
import React, { useEffect, useRef, useState } from "react";

const CategoryDropdown = ({
  allCategory,
  leadCategoryData,
  handleSubmit,
  setOutSideFocus,
}) => {
  const [categoryOption, setCategoryOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Close dropdown after selection
    handleSubmit({ category: option?.value }); // Call the handleSubmit function with the selected option
  };

  useEffect(() => {
    if (isValidArray(allCategory?.data?.listCategory?.response)) {
      setCategoryOption(
        allCategory?.data?.listCategory?.response?.map((category) => ({
          label: category?.title,
          value: category?._id,
          backgroundColor: category?.color,
          textColor: category?.textColor,
        }))
      );
      setSelectedOption({
        label: leadCategoryData?.title,
        value: leadCategoryData?._id,
        backgroundColor: leadCategoryData?.color,
        textColor: leadCategoryData?.textColor,
      });
    }
  }, [allCategory, leadCategoryData]);

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle click outside to close input box
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <div
      className="relative inline-block xs2:mt-0 mt-1"
      ref={popupRef}
      onBlur={() => setOutSideFocus(false)}
      onFocus={() => setOutSideFocus(true)}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={{
          backgroundColor: selectedOption?.backgroundColor || "#60a5fa00",
          color: selectedOption?.textColor || "#ffffff",
        }}
        className="px-1 py-[2px] rounded-md flex text-xs"
      >
        <span
          title={selectedOption?.label}
          className="max-w-[150px] block truncate"
        >
          {selectedOption ? selectedOption?.label : "select Category"}
        </span>
        <span className="float-right">{isOpen ? "▲" : "▼"}</span>
      </button>
      {isOpen && (
        <div
          className="absolute mt-1 text-nowrap bg-white border shadow-xl text-xs z-40 rounded-md max-h-[350px] overflow-y-auto"
          onClick={(e) => {
            e.stopPropagation(); // Prevent event from propagating up
          }}
        >
          {categoryOption
            ?.filter((type) => type?.value !== leadCategoryData?._id)
            ?.map((option) => (
              <div
                key={option?.value}
                onClick={() => handleSelect(option)}
                className="cursor-pointer mx-1 my-1 rounded-md py-[2px] px-[4px]"
                style={{
                  backgroundColor: option?.backgroundColor,
                  color: option?.textColor,
                }}
              >
                {option?.label}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default CategoryDropdown;
