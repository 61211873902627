import {
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
} from "@heroicons/react/24/solid";
import moveIcon from "Assets/icons/moveIcon.svg";
import trashIcon from "Assets/icons/trashIcon.svg";
import Button from "Components/Common/Buttons/Button";
import { callStatusOptions } from "Components/Leads/assetData";
import {
  convertCapitalize,
  isPermission,
  isValidArray,
  showErrorMsg,
  showLeadDateTimeFormat,
  showSuccessMsg,
} from "Modules/util";
import {
  useDeleteCallMutation,
  useGetCallByLeadIdQuery,
} from "Redux/Leads/Call/call";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddCallLogModal from "../AddCallLogModal";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import Skeleton from "Components/Common/Fields/Skeleton";

const CallLogsHistory = ({
  lead,
  drag,
  canDrop,
  setIsOpenNestModal = () => {},
}) => {
  const userPermissions = useSelector((state) => state?.user?.permission); // Fetching user permissions from Redux state
  const [isOpenAddCallForm, setIsOpenAddCallForm] = useState(false); // State to manage the visibility of the "Add Call Log" modal
  const callContainerRef = useRef(null); // Ref to manage scroll position in the call logs container
  const [isDeleteNoteModal, setIsDeleteNoteModal] = useState(false); // State for delete confirmation modal
  const [id, setId] = useState(); // State for set delete call
  const [deleteCall] = useDeleteCallMutation(); // Mutation hook for deleting a call

  const [callList, setCallList] = useState([]);
  const leadId = lead?.leadResponse?.[0]?._id;

  // lead wise task list api
  const {
    data: getCallByLeadId,
    isLoading: isCallListLoading,
    refetch: callListRefetch,
  } = useGetCallByLeadIdQuery(
    {
      _id: leadId,
    },
    { skip: leadId ? false : true }
  );

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      addCallLogModal: isOpenAddCallForm,
      deleteCallModal: isDeleteNoteModal,
    }));
  }, [isOpenAddCallForm, isDeleteNoteModal]);

  useEffect(() => {
    let callListData = getCallByLeadId?.data?.getCallByLeadId?.callResponse;
    if (!isCallListLoading) {
      if (isValidArray(callListData)) {
        setCallList(callListData);
      } else {
        setCallList([]);
      }
    }
  }, [getCallByLeadId]);

  // Auto-scroll to the bottom when new call logs are added
  useEffect(() => {
    setTimeout(() => {
      if (callContainerRef?.current) {
        callContainerRef.current.scrollTop = 0;
      }
    }, 50);
  }, [callList]);

  //Handle delete Call
  function handleDeleteCall() {
    let payload = {
      leadId: leadId,
      callId: id,
    };
    //Delete call Mutation call
    deleteCall(payload)
      .then((response) => {
        if (response?.data?.data?.deleteCall?.type === "success") {
          showSuccessMsg(response?.data?.data?.deleteCall?.message);
        } else if (response?.data?.data?.deleteCall?.type === "error") {
          showErrorMsg(response?.data?.data?.deleteCall?.message);
        }
      })
      .catch((error) => showErrorMsg("Error occurred while Deleting Call."));
    setIsDeleteNoteModal(false);
  }

  return (
    <>
      {/* Modal for adding a new call log */}
      <AddCallLogModal
        isOpen={isOpenAddCallForm}
        setIsOpen={setIsOpenAddCallForm}
        refetch={callListRefetch}
        leadData={isValidArray(lead?.leadResponse) ? lead?.leadResponse[0] : []}
      />
      <ConfirmationModal
        isModalOpen={isDeleteNoteModal}
        setModalOpen={setIsDeleteNoteModal}
        handleAction={() => handleDeleteCall()}
      />
      <div className="w-full sm:!min-w-[475px] shadow-md rounded-2xl">
        <div
          className={`bg-cyan-400 !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
            canDrop && "!bg-cyan-300 opacity-85"
          }`}
        >
          <div className="space-x-2 flex items-center ">
            <div className="flex">
              <div className="bg-white w-0.5"></div>
              <div className="text-white pl-[7px] font-medium">
                Call Logs history
              </div>
              <span className="text-black font-normal text-xs flex items-center ml-2">
                ( Unresponsive Count:{" "}
                {getCallByLeadId?.data?.getCallByLeadId?.unresponsiveCallCount ?? "-"}{" "}
                )
              </span>
            </div>
          </div>
          <div className="flex space-x-3">
            {isPermission(userPermissions, "make_call") && (
              <Button
                label={"Add"}
                title={"Add Call Log"}
                className="btn-call-log text-sm font-medium rounded-lg"
                action={() => setIsOpenAddCallForm(true)}
              />
            )}
            <div
              className="bg-cyan-600 rounded-md flex items-center px-1.5 h-7 w-7 cursor-move"
              ref={drag}
            >
              <img
                alt="edit-icon"
                src={moveIcon}
                className="h-4 w-4 rounded-md filter invert brightness-0"
                title={"Move Segment"}
              />
            </div>
          </div>
        </div>
        <div
          className={`leadDeatils-card-body !h-[390px] rounded-b-2xl overflow-y-auto scrollbar-cyan space-y-3 py-2 px-1 ${
            canDrop && "!bg-cyan-300 opacity-85"
          }`}
          ref={callContainerRef}
        >
          {isCallListLoading ? (
            <Skeleton counter={5} height={"60px"} className="my-3" />
          ) : isValidArray(callList) ? (
            callList?.map((log, index) => (
              <React.Fragment key={index} className={``}>
                {log?.leadCallStatus ? (
                  <div
                    key={index}
                    className={`m-2 p-2 bg-gray-100 shadow-md rounded-lg text-sm space-y-2 `}
                  >
                    <div className="xs3:flex items-center w-full justify-between">
                      <p className="flex items-start text-xs font-medium">
                        {/* {callLogIcons[log?.leadCallStatus]} */}
                        {
                          callStatusOptions.find(
                            (obj) => obj?.value === log?.leadCallStatus
                          )?.icon
                        }
                        {convertCapitalize(log?.leadCallStatus) || ""}
                      </p>
                      <div className="flex justify-end items-end space-x-2">
                        <p className="text-gray text-xs">
                          {showLeadDateTimeFormat(log?.createdAt)}
                        </p>
                        {isPermission(userPermissions, "delete_call") ? (
                          <div className="bg-white rounded-md p-1">
                            <img
                              alt="edit-icon"
                              src={trashIcon}
                              className="h-4 w-4 hover:cursor-pointer rounded-md"
                              onClick={() => {
                                setIsDeleteNoteModal(true);
                                setId(log?._id);
                              }}
                              title={"Delete Call"}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {log?.type !== "Manually" && log?.callType && (
                      <div className="text-xs">
                        <div className="flex justify-between">
                          <h2 className="font-semibold flex text-gray-600">
                            <span className="mr-2">From:</span>
                            <span className="flex  bg-gray-200 px-1 rounded-md">
                              <PhoneArrowUpRightIcon className="w-3 text-cyan-600 mr-1" />
                              {log?.callType === "received"
                                ? log?.userPhoneNo
                                : log?.leadPhoneNo}
                            </span>
                            <span className="mx-2">To:</span>
                            <span className="flex  bg-gray-200 px-1 rounded-md">
                              <PhoneArrowDownLeftIcon className="w-3 text-cyan-600 mr-1" />
                              {log?.callType === "received"
                                ? log?.leadPhoneNo
                                : log?.userPhoneNo}
                            </span>
                          </h2>
                          <p className="text-gray-700">
                            {log?.leadCallDuration} seconds
                          </p>
                        </div>
                      </div>
                    )}
                    <p className="text-xs">
                      <span className="text-gray">Note:</span>{" "}
                      {log?.note?.length ? log?.note : "N/A"}
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))
          ) : (
            <div className="flex justify-center items-center h-full text-gray-600 text-xs">
              <span>No call logs history available.</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CallLogsHistory;
