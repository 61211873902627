export const gqlBody = {
  PendingMessageList(payload) {
    return {
      query: `query PendingMessageList($input: PendingMessageListInput) {
          pendingMessageList(input: $input) {
            code
            message
            response {
              leadId
              leadName
              nodeType
              dripName
              dripId
              scheduleTime
              message
              isOpenAI
              nodeId
              subject
            }
            totalCount
            type
          }
        }`,
      variables: {
        input: payload,
      },
    };
  },
  LeadDripMessageSave(payload) {
    return {
      query: `mutation LeadDripMessageSave($input: LeadDripMessageSaveInput) {
        leadDripMessageSave(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
};
