import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a Drip object using serverApi.injectEndpoints
const Drip = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    GetDripByLeadId: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getDripByLeadId(payload),
        };
      },
      providesTags: ["DripByLeadId"],
    }),
    DripScheduleTime: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.DripScheduleTime(payload),
        };
      },
      providesTags: ["DripScheduleTime"],
    }),
    RescheduleDripLead: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.RescheduleDripLead(payload),
        };
      },
      invalidatesTags: ["DripByLeadId", "DripScheduleTime"],
    }),
    GetDripListByLeadId: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.getDripByLeadId(payload),
        };
      },
    }),
    skipCurrentNode: builder.mutation({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.skipCurrentNode(payload),
        };
      },
      invalidatesTags: ["Lead", "DripByLeadId"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useGetDripByLeadIdQuery,
  useRescheduleDripLeadMutation,
  useDripScheduleTimeQuery,
  useGetDripListByLeadIdMutation,
  useSkipCurrentNodeMutation
} = Drip;
