import Tippy from "@tippyjs/react";
import { useState } from "react";

export const TooltipWrapper = ({ message }) => {
  const [toolTipData, setToolTipData] = useState("");

  return (
    <Tippy
      allowHTML={true}
      interactive={true}
      placement={"bottom"}
      animateFill={true}
      animation={"scale"}
      appendTo={document.body}
      maxWidth={"500px"}
      content={
        toolTipData ? (
          <div
            className="text-justify p-1"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{ __html: toolTipData }}
          ></div>
        ) : null
      }
    >
      <div
        className="text-justify leading-6 max-h-[50px] w-fit overflow-y-auto p-2"
        onMouseOver={() => setToolTipData(message)}
        style={{ whiteSpace: "pre-wrap" }}
        dangerouslySetInnerHTML={{ __html: message }}
      ></div>
    </Tippy>
  );
};
