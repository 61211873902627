import {
  Bold,
  Essentials,
  Italic,
  AutoImage,
  PictureEditing,
  Paragraph,
  Underline,
  List,
  Link,
  ImageToolbar,
  ImageStyle,
  ImageUpload,
  SimpleUploadAdapter,
  Heading,
  FontColor,
  FontBackgroundColor,
  Alignment,
  Indent,
  ImageInsertViaUrl,
  LinkImage,
  Autosave,
  CloudServices,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  ImageBlock,
  ImageInline,
  ImageInsert,
  ImageResize,
  SourceEditing,
  // Table,
  // TableCaption,
  // TableCellProperties,
  // TableColumnResize,
  // TableProperties,
  // TableToolbar,
  Undo,
  Strikethrough,
  BlockQuote,
  Image,
  ImageCaption,
  Font,
  Subscript,
  Superscript,
  CodeBlock,
  HorizontalLine,
  // MediaEmbed,
  ImageResizeEditing,
  ImageResizeHandles,
  IndentBlock,
  Highlight,
  AutoLink,
  TodoList,
  SpecialCharacters,
  SpecialCharactersEssentials,
  HtmlEmbed,
} from "ckeditor5";

export const config = {
  plugins: [
    Alignment,
    AutoImage,
    Autosave,
    Bold,
    CloudServices,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    ImageBlock,
    ImageInline,
    ImageInsert,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    LinkImage,
    List,
    Paragraph,
    PictureEditing,
    SimpleUploadAdapter,
    SourceEditing,
    // Table,
    // TableCaption,
    // TableCellProperties,
    // TableColumnResize,
    // TableProperties,
    // TableToolbar,
    Underline,
    Undo,
    Link,
    Strikethrough,
    BlockQuote,
    Image,
    ImageCaption,
    Font,
    Subscript,
    Superscript,
    CodeBlock,
    HorizontalLine,
    // MediaEmbed,
    HtmlEmbed,
    ImageResizeEditing,
    ImageResizeHandles,
    Highlight,
    AutoLink,
    TodoList,
    SpecialCharacters,
    SpecialCharactersEssentials,
  ],
  toolbar: {
    items: [
      // Headings and Formatting
      "heading",
      "|",
      "fontFamily",
      "fontSize",
      "|",

      // Text Styles
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "code",
      "subscript",
      "superscript",
      "fontColor",
      "fontBackgroundColor",
      "|",

      // Lists and Indentation
      "numberedList",
      "bulletedList",
      "outdent",
      "indent",
      "|",

      // Quotes and Alignment
      "blockQuote",
      "alignment",
      "|",

      // Images and Media
      "uploadImage",
      "insertImageViaUrl",
      // "mediaEmbed",
      "horizontalLine",
      "|",

      // // Tables
      // "insertTable",
      // "tableColumn",
      // "tableRow",
      // "mergeTableCells",
      // "|",

      // Links and Special Characters
      "link",
      "autoLink",
      "specialCharacters",
      "|",

      // Code and Highlighting
      "codeBlock",
      "highlight",
      "|",

      // Undo/Redo
      "undo",
      "redo",
      "|",

      // Additional
      "toggleImageCaption",
      "ckboxImageEdit",
      "todoList",
    ],
    shouldNotGroupWhenFull: true, // Ensures the toolbar wraps into a dropdown
  },
  image: {
    toolbar: [
      "imageStyle:inline",
      "imageStyle:wrapText",
      "imageStyle:breakText",
      "|",
      "resizeImage",
    ],
    styles: ["full", "side"],
    resizeOptions: [
      {
        name: "resizeImage:original",
        value: null,
        label: "Original",
      },
      {
        name: "resizeImage:40",
        value: "40",
        label: "40%",
      },
      {
        name: "resizeImage:60",
        value: "60",
        label: "60%",
      },
    ],
  },
  fontFamily: {
    supportAllValues: true,
  },
  fontSize: {
    options: [10, 12, 14, "default", 18, 20, 22],
    supportAllValues: true,
  },
  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: "a",
        attributes: ["href", "target"],
        classes: true,
        styles: true,
      },
    ],
  },
  placeholder: "Enter Email Body",
  simpleUpload: {
    uploadUrl: `${process.env.REACT_APP_BASEURL_ACTION}/api/v1/common/email/upload-image`,
  },

  insertImageViaUrl: {
    toolbar: ["insertImageViaUrl", "resizeImage"],
  },
  alignment: {
    options: ["left", "center", "right", "justify"],
  },
  shouldNotGroupWhenFull: true,
};

export const insertHtmlAtCursor = (editor, html) => {
  const viewFragment = editor.data.processor.toView(html);
  const modelFragment = editor.data.toModel(viewFragment);
  editor.model.insertContent(modelFragment, editor.model.document.selection);
};

export const handleButtonClick = (value, editorRef) => {
  if (editorRef.current) {
    const editor = editorRef.current.editor;

    const html =
      value === "unsubscribeLink"
        ? `If you no longer wish to receive these emails, you can <a href='{{unsubscribeLink}}' target="_blank">unsubscribe here</a>`
        : `{{${value}}}`;

    insertHtmlAtCursor(editor, html);
  }
};
