import { isArray, isNumber } from "lodash";
import {
  isValidArray,
  showDateAndTimeFormat,
  showLeadDateTimeFormat,
} from "Modules/util";
import moment from "moment/moment";

// Define headers for merge lead table
export const mergeLeadHeader = [
  "Field Name",
  "Current Lead",
  "Incoming Lead",
  "Other",
];

const checkRadioValue = (leadValue) => {
  if (isNumber(leadValue)) {
    return leadValue !== 0;
  } else if (isArray(leadValue)) {
    return isValidArray(leadValue);
  } else {
    return (
      leadValue?.trim()?.length !== 0 && leadValue && leadValue !== "No Data"
    );
  }
};

export const createField = (
  label,
  leadValue,
  mergeValue,
  radioBtn,
  bothOption = false,
  isHideRadioBtn = false
) => {
  isHideRadioBtn = !checkRadioValue(leadValue) && !checkRadioValue(mergeValue);
  return {
    label,
    leadValue: isArray(leadValue) ? leadValue : leadValue ? [leadValue] : [],
    mergeValue: isArray(mergeValue)
      ? mergeValue
      : mergeValue
      ? [mergeValue]
      : [],
    radioBtn,
    bothOption: bothOption,
    isHideRadioBtn: isHideRadioBtn,
    currentDisabled: !checkRadioValue(leadValue),
    incomingDisabled: !checkRadioValue(mergeValue),
    bothDisabled: !checkRadioValue(leadValue) || !checkRadioValue(mergeValue),
  };
};

export const formatItemCount = (count) =>
  count ? `${count} item${count !== 1 ? "s" : ""}` : "No Data";

// Helper function to determine the initial value based on current and incoming lead data
export const getInitialValue = (
  currentLeadValue,
  incomingLeadValue,
  isBoth = false
) => {
  // Return "current" if neither value is present or only the current value is available
  if (currentLeadValue && incomingLeadValue && isBoth) return "both";
  if (!currentLeadValue && !incomingLeadValue) return "current";
  if (currentLeadValue && !incomingLeadValue) return "current";

  // Return "incoming" if only the incoming value is available
  if (!currentLeadValue && incomingLeadValue) return "incoming";

  // Default to "current" if both values are present
  return "current";
};

export const mergeLeadPayload = (values, lead, mergeLead) => {
  return {
    isAddressMerge: values?.address === "incoming",
    isRegisterDateMerge: values?.registerDate === "incoming",
    agent:
      values?.agent === "incoming"
        ? mergeLead?.agentResponse?.[0]?._id
        : lead?.agentResponse?.[0]?._id,
    isAgentMerge: values?.agent === "incoming",
    isBirthDayMerge: values?.birthday === "incoming",

    category:
      values?.categories === "incoming"
        ? mergeLead?.categoryResponse?.[0]?._id || null
        : lead?.categoryResponse?.[0]?._id || null,

    isCategoryMerge: values?.categories === "incoming",

    isCloseMerge:
      values?.categories === "incoming" && mergeLead?.isClosed ? true : false,

    isCityMerge: values?.city === "incoming",
    currentLeadId: lead?._id,

    isDescriptionMerge: values?.description === "incoming",
    mergeBothDescription: values?.description === "both",

    // drips: isValidArray(values?.newAddDrips) ? values?.newAddDrips : [],
    isDripMerge: values?.drips === "incoming",
    mergeBothDrip: values?.drips === "both",

    currentEmail: isValidArray(values?.currentEmail)
      ? values?.currentEmail
      : [],

    incomingEmail: isValidArray(values?.incomingEmail)
      ? values?.incomingEmail
      : [],

    isFirstNameMerge: values?.firstName === "incoming",
    incomingLeadId: mergeLead?._id,
    isCallLogMerge: values?.callCount === "incoming",
    mergeBothCallLog: values?.callCount === "both",

    isEAlertMerge: values?.EAlertCount === "incoming",
    mergeBothEAlert: values?.EAlertCount === "both",

    isEmailMerge: values?.emailCount === "incoming",
    mergeBothEmail: values?.emailCount === "both",

    isNoteMerge: values?.noteCount === "incoming",
    mergeBothNote: values?.noteCount === "both",

    isSMSMerge: values?.smsCount === "incoming",
    mergeBothSMS: values?.smsCount === "both",

    isTaskMerge: values?.taskCount === "incoming",
    mergeBothTask: values?.taskCount === "both",
    isLastNameMerge: values?.lastName === "incoming",
    // lastName:
    //   values?.lastName === "incoming" ? mergeLead?.lastName : lead?.lastName,

    isLeadTypeMerge: values?.leadType === "incoming",
    lender:
      values?.lender === "incoming"
        ? mergeLead?.lenderResponse?.[0]?._id
        : lead?.lenderResponse?.[0]?._id,
    isLenderMerge: values?.lender === "incoming",

    currentPhones: isValidArray(values?.currentPhone)
      ? values?.currentPhone
      : [],
    incomingPhones: isValidArray(values?.incomingPhone)
      ? values?.incomingPhone
      : [],
    source: values?.source === "incoming" ? mergeLead?.source : lead?.source,
    isSourceMerge: values?.source === "incoming",
    isStateMerge: values?.state === "incoming",
    currentTags: values?.currentTags,
    incomingTags: values?.incomingTags,
    isZipCodeMerge: values?.zipCode === "incoming",
    removeIncomingLead: values?.isDeleteIncomingLead,
    selectIncomingStory: values?.story === "incoming",
    generateNewStory: values?.story === "both",
    isFollowUpMerge: values?.followUp === "incoming",
  };
};

export const tableRowData = (lead, mergeLead, sourceList) => {
  return [
    createField(
      "First Name",
      lead?.firstName,
      mergeLead?.firstName,
      "firstName"
    ),
    createField("Last Name", lead?.lastName, mergeLead?.lastName, "lastName"),
    (() => {
      return {
        label: "Phone",
        leadValue: isValidArray(lead?.phone) ? lead?.phone : [],
        mergeValue: isValidArray(mergeLead?.phone) ? mergeLead?.phone : [],
        radioBtn: "Phone",
        bothOption: false,
        currentDisabled: false,
        incomingDisabled: false,
        isHideRadioBtn: true,
        isCheckbox: true,
        currentCheckBoxValue: isValidArray(lead?.phone) ? lead?.phone : [],
        incomingCheckBoxValue: isValidArray(mergeLead?.phone)
          ? mergeLead?.phone
          : [],
      };
    })(),
    createField(
      "RegisterDate",
      lead?.registerDate ? showLeadDateTimeFormat(lead?.registerDate) : "",
      mergeLead?.registerDate
        ? showLeadDateTimeFormat(mergeLead?.registerDate)
        : "",
      "registerDate"
    ),
    (() => {
      return {
        label: "Email",
        leadValue: isValidArray(lead?.email) ? lead?.email : [],
        mergeValue: isValidArray(mergeLead?.email) ? mergeLead?.email : [],
        radioBtn: "Email",
        bothOption: false,
        currentDisabled: false,
        incomingDisabled: false,
        isHideRadioBtn: true,
        isCheckbox: true,
        currentCheckBoxValue: isValidArray(lead?.email) ? lead?.email : [],
        incomingCheckBoxValue: isValidArray(mergeLead?.email)
          ? mergeLead?.email
          : [],
      };
    })(),
    createField(
      "Address",
      lead?.address,
      mergeLead?.address,
      "address",
      false,
      !lead?.address && !mergeLead?.address
    ),
    createField("ZipCode", lead?.zipCode, mergeLead?.zipCode, "zipCode"),
    createField("City", lead?.city, mergeLead?.city, "city"),
    createField("State", lead?.state, mergeLead?.state, "state"),
    (() => {
      const leadBirthday =
        lead?.birthMonth && lead?.birthDate && lead?.birthYear
          ? moment(
              `${lead?.birthMonth} ${lead?.birthDate}, ${lead?.birthYear}`
            )?.format("MMMM Do, YYYY")
          : "";
      const mergeLeadBirthday =
        mergeLead?.birthMonth && mergeLead?.birthDate && mergeLead?.birthYear
          ? moment(
              `${mergeLead?.birthMonth} ${mergeLead?.birthDate}, ${mergeLead?.birthYear}`
            )?.format("MMMM Do, YYYY")
          : "";
      return createField(
        "Birthday",
        leadBirthday,
        mergeLeadBirthday,
        "birthday"
      );
    })(),
    (() => {
      const leadValue = isValidArray(lead?.agentResponse)
        ? [lead?.agentResponse?.[0]?.name]
        : [];
      const mergeValue = isValidArray(mergeLead?.agentResponse)
        ? [mergeLead?.agentResponse?.[0]?.name]
        : [];
      return {
        label: "Agent",
        leadValue: leadValue,
        mergeValue: mergeValue,
        radioBtn: "agent",
        bothOption: false,
        isHideRadioBtn: !isValidArray(leadValue) && !isValidArray(mergeValue),
      };
    })(),
    (() => {
      const leadValue = isValidArray(lead?.lenderResponse)
        ? [lead?.lenderResponse?.[0]?.name]
        : [];
      const mergeValue = isValidArray(mergeLead?.lenderResponse)
        ? [mergeLead?.lenderResponse?.[0]?.name]
        : [];
      return {
        label: "Lender",
        leadValue: leadValue,
        mergeValue: mergeValue,
        radioBtn: "lender",
        bothOption: false,
        isHideRadioBtn: !isValidArray(leadValue) && !isValidArray(mergeValue),
        currentDisabled: !isValidArray(leadValue),
        incomingDisabled: !isValidArray(mergeValue),
      };
    })(),
    (() => {
      const leadSource = sourceList?.find((i) => i?.value === lead?.source);
      const mergeLeadSource = sourceList?.find(
        (i) => i?.value === mergeLead?.source
      );
      return {
        label: "Source",
        leadValue: lead?.source ? [leadSource?.label] : [],
        mergeValue: mergeLead?.source ? [mergeLeadSource?.label] : [],
        radioBtn: "source",
        bothOption: false,
        isHideRadioBtn: !leadSource?.label && !mergeLeadSource?.label,
        currentDisabled: !leadSource?.label,
        incomingDisabled: !mergeLeadSource?.label,
      };
    })(),
    (() => {
      const leadValue =
        lead?.leadType && lead?.leadType !== "Not Defined"
          ? [lead?.leadType]
          : [];

      const mergeLeadValue =
        mergeLead?.leadType && mergeLead?.leadType !== "Not Defined"
          ? [mergeLead?.leadType]
          : [];
      return {
        label: "Lead Type",
        leadValue: leadValue,
        mergeValue: mergeLeadValue,
        radioBtn: "leadType",
        bothOption: false,
        isHideRadioBtn:
          !isValidArray(leadValue) && !isValidArray(mergeLeadValue),
        currentDisabled: !isValidArray(leadValue),
        incomingDisabled: !isValidArray(mergeLeadValue),
      };
    })(),
    (() => {
      const leadValue = isValidArray(lead?.categoryResponse)
        ? lead?.categoryResponse?.map((i) => (
            <span
              style={{
                backgroundColor: i?.color,
                color: i?.textColor,
              }}
              className=" px-2 py-[2px] rounded-md"
            >
              {i?.title}
            </span>
          ))
        : [];
      const mergeLeadValue = isValidArray(mergeLead?.categoryResponse)
        ? mergeLead?.categoryResponse?.map((i) => (
            <span
              style={{
                backgroundColor: i?.color,
                color: i?.textColor,
              }}
              className=" px-2 py-[2px] rounded-md"
            >
              {i?.title}
            </span>
          ))
        : [];

      return {
        label: "Category",
        leadValue: leadValue,
        mergeValue: mergeLeadValue,
        radioBtn: "categories",
        bothOption: false,
        isHideRadioBtn:
          !isValidArray(leadValue) && !isValidArray(mergeLeadValue),
        currentDisabled: !isValidArray(leadValue),
        incomingDisabled: !isValidArray(mergeLeadValue),
      };
    })(),

    (() => {
      const leadValue = isValidArray(lead?.tagResponse)
        ? lead?.tagResponse?.map((i) => i?.title)
        : [];
      const mergeLeadValue = isValidArray(mergeLead?.tagResponse)
        ? mergeLead?.tagResponse?.map((i) => i?.title)
        : [];
      const currentCheckBoxValue = isValidArray(lead?.tagResponse)
        ? lead?.tagResponse?.map((i) => i?._id)
        : [];
      const incomingCheckBoxValue = isValidArray(mergeLead?.tagResponse)
        ? mergeLead?.tagResponse?.map((i) => i?._id)
        : [];
      return {
        label: "Tags",
        leadValue: leadValue,
        mergeValue: mergeLeadValue,
        radioBtn: "Tags",
        bothOption: false,
        isHideRadioBtn: true,
        currentDisabled: false,
        incomingDisabled: false,
        bothDisabled: false,
        isCheckbox: true,
        currentCheckBoxValue: currentCheckBoxValue,
        incomingCheckBoxValue: incomingCheckBoxValue,
      };
    })(),
    (() => {
      return {
        label: "Description",
        leadValue: lead?.description ? [lead?.description] : [],
        mergeValue: mergeLead?.description ? [mergeLead?.description] : [],
        radioBtn: "description",
        bothOption: true,
        bothDisabled: !mergeLead?.description || !lead?.description,
        isHideRadioBtn: !mergeLead?.description && !lead?.description,
        currentDisabled: !lead?.description,
        incomingDisabled: !mergeLead?.description,
      };
    })(),
    createField(
      "E-Alerts",
      formatItemCount(lead?.EAlertCount),
      formatItemCount(mergeLead?.EAlertCount),
      "EAlertCount",
      true
    ),
    createField(
      "Note History",
      formatItemCount(lead?.noteCount),
      formatItemCount(mergeLead?.noteCount),
      "noteCount",
      true
    ),
    createField(
      "Call History",
      formatItemCount(lead?.callCount),
      formatItemCount(mergeLead?.callCount),
      "callCount",
      true
    ),
    createField(
      "SMS History",
      formatItemCount(lead?.smsCount),
      formatItemCount(mergeLead?.smsCount),
      "smsCount",
      true
    ),
    createField(
      "Tasks",
      formatItemCount(lead?.taskCount),
      formatItemCount(mergeLead?.taskCount),
      "taskCount",
      true
    ),
    createField(
      "Email History",
      formatItemCount(lead?.emailCount),
      formatItemCount(mergeLead?.emailCount),
      "emailCount",
      true
    ),
    (() => {
      return {
        label: "Story and Highlights",
        leadValue: lead?.story
          ? [`${lead?.firstName}'s Story and Highlights`]
          : [],
        mergeValue: mergeLead?.story
          ? [`${mergeLead?.firstName}'s Story and Highlights`]
          : [],
        radioBtn: "story",
        bothOption: true,
        isHideRadioBtn: !lead?.story && !mergeLead?.story,
        currentDisabled: !lead?.story,
        incomingDisabled: !mergeLead?.story,
        bothDisabled: !lead?.story && !mergeLead?.story,
      };
    })(),
    (() => {
      const leadValue = (
        <span>
          {lead?.followUpCount + 1}/5 Auto Follow-up on{" "}
          {showDateAndTimeFormat(lead?.followUpScheduledDateTime)}
        </span>
      );
      const mergeLeadValue = (
        <span>
          {mergeLead?.followUpCount + 1}/5 Auto Follow-up on{" "}
          {showDateAndTimeFormat(mergeLead?.followUpScheduledDateTime)}
        </span>
      );
      return {
        label: "Auto Follow Up",
        leadValue: lead?.isFollowUp ? [leadValue] : ["Off"],
        mergeValue: mergeLead?.isFollowUp ? [mergeLeadValue] : ["Off"],
        radioBtn: "followUp",
        bothOption: false,
        isHideRadioBtn: !lead?.isFollowUp && !mergeLead?.isFollowUp,
        currentDisabled: !lead?.isFollowUp && !mergeLead?.isFollowUp,
        incomingDisabled: !lead?.isFollowUp && !mergeLead?.isFollowUp,
      };
    })(),
  ];
};
