import { dropDownMonths } from "Modules/data";
import { useEffect } from "react";
import DateSelectField from "./DateSelectField";

const DatedropDownPicker = (props) => {
  const {
    selectedMonth,
    setSelectedMonth,
    selectedDay,
    setSelectedDay,
    selectedYear,
    setSelectedYear,
    daysOptions,
    setDaysOptions,
    validationErros,
  } = props;

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => ({
    value: currentYear - i,
    label: `${currentYear - i}`,
  }));

  // Function to update days options based on selected month and year
  useEffect(() => {
    if (selectedMonth) {
      const daysInMonth = new Date(
        selectedYear?.value || currentYear,
        selectedMonth.value,
        0
      ).getDate();
      const days = Array.from({ length: daysInMonth }, (_, i) => ({
        value: i + 1,
        label: `${i + 1}`,
      }));
      setDaysOptions(days);
      // Reset selected day if it's out of range
      if (selectedDay && selectedDay.value > daysInMonth) {
        setSelectedDay(null);
      }
    }
  }, [selectedMonth, selectedYear]);

  // Handle month selection
  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };

  // Handle day selection
  const handleDayChange = (value) => {
    setSelectedDay(value);
  };

  // Handle year selection
  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  return (
    <div className="sm:flex w-full space-y-2 sm:space-y-0">
      <div className="sm:w-1/3">
        <DateSelectField
          isOpen={true}
          placeholder="Select Month"
          options={dropDownMonths}
          value={selectedMonth}
          isDisabled={false}
          onChange={handleMonthChange}
        />
        {validationErros?.selectedMonth && (
          <div className="!text-xs text-red-500 ml-2 mt-1">
            {validationErros?.selectedMonth}
          </div>
        )}
      </div>
      <div className="sm:w-1/3">
        <DateSelectField
          isOpen={true}
          placeholder="Select Day"
          options={daysOptions}
          isDisabled={selectedMonth?.value ? false : true}
          value={selectedDay}
          onChange={handleDayChange}
        />
        {validationErros?.selectedDay && (
          <div className="!text-xs text-red-500 ml-2 mt-1">
            {validationErros?.selectedDay}
          </div>
        )}
      </div>
      <div className="sm:w-1/3">
        <DateSelectField
          isOpen={true}
          placeholder="Select Year"
          options={years}
          isDisabled={selectedDay?.value ? false : true}
          value={selectedYear}
          onChange={handleYearChange}
        />
        {validationErros?.selectedYear && (
          <div className="!text-xs text-red-500 ml-2 mt-1">
            {validationErros?.selectedYear}
          </div>
        )}
        {validationErros?.errors && (
          <div className="!text-xs text-red-500 ml-2 mt-1">
            {validationErros?.errors}
          </div>
        )}
      </div>
    </div>
  );
};

export default DatedropDownPicker;
