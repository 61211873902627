import { useRef } from 'react';

const useDoubleTap = (onDoubleTap, delay = 300) => {
  const lastTap = useRef(0);

  const handleTouch = (e) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTap.current;

    if (tapLength < delay && tapLength > 0) {
      onDoubleTap(e);
    }

    lastTap.current = currentTime;
  };

  return handleTouch;
};

export default useDoubleTap;
